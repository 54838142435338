import React, { useState, useEffect } from "react";
import "./style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModuleTopics from "./ModuleTopics";
import { Edit } from "@mui/icons-material";

const CourseModule = ({ setIsOpen, selectedId }) => {
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [moduleLIst, setModuleList] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [open, setOpen] = useState(false);
  const [topicOpen, setTopicOpen] = useState(false);
  const [topicId, setTopicId] = useState("");
  const [newCourseModule, setNewCourseModule] = useState("");
  const [isEditing, setIsEditing] = useState(null);
  const [editedModuleName, setEditedModuleName] = useState("");

  const handleAddModule = async () => {
    try {
      setOpen(true);

      const response = await fetch(
        "https://api.artvilleacademy.com/admin/add_module",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: newCourseModule,
            course_id: selectedId,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setNewCourseModule("");
        setRefreshData((prevState) => !prevState);
        setOpen(false);
        Swal.fire({
          icon: "success",
          text: "Module added successfully",
        });
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error adding Module:", error);
      setOpen(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add Module. Please try again later.",
      });
    }
  };

  useEffect(() => {
    setOpen(true);
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/modules",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              access_level: parsedData?.access_level,
              course_id: selectedId,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setModuleList(data.modules);
          setOpen(false);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOpen(false);
      }
    };

    fetchData();
  }, [refreshData]);

  const handleEditStart = (id, name) => {
    setIsEditing(id);
    setEditedModuleName(name);
  };

  const handleEditCancel = () => {
    setIsEditing(null);
    setEditedModuleName("");
  };

  const handleEditSave = async (id) => {
    try {
      setOpen(true);
      const response = await fetch(`https://api.artvilleacademy.com/admin/update/module`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          module_id: id,
          name: editedModuleName,
        }),
      });

      if (response.ok) {
        setRefreshData((prevState) => !prevState); // Refresh the module list
        setIsEditing(null);
        setEditedModuleName("");
        setOpen(false);
        Swal.fire({
          icon: "success",
          text: "Module updated successfully",
        });
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error updating module:", error);
      setOpen(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update module. Please try again later.",
      });
    }
  };

  const handleModuleClick = (id) => {
    setTopicOpen(true);
    setTopicId(id);
  };

  const handleDelete = async (id, name) => {
    try {
      const confirmDelete = await Swal.fire({
        title: `Are you sure you want to delete ${name}?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          popup: "my-popup",
        },
        width: 300,
        height: 200,
      });

      if (confirmDelete.isConfirmed) {
        const response = await fetch(
          `https://api.artvilleacademy.com/admin/modules/delete`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              module_id: id,
              access_level: parsedData?.access_level,
            }),
          }
        );

        if (response.ok) {
          Swal.fire({
            icon: "success",
            text: "Delete successfully",
          });
          setRefreshData((prevState) => !prevState);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to delete. Please try again later.",
          });
        }
      }
    } catch (error) {
      console.error("Error deleting module:", error);
    }
  };

  return (
    <>
      {topicOpen ? (
        <ModuleTopics setTopicOpen={setTopicOpen} topicId={topicId} />
      ) : (
        <>
          <div className="add-course-div">
            <button
              className="button-81"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <ArrowBackIcon />
            </button>
          </div>
          <div className="Teacher-main-div">
            {open ? (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="names">Sl No</TableCell>
                        <TableCell className="names">Course Name</TableCell>
                        <TableCell className="names"> Module Name</TableCell>
                        <TableCell className="names">Edit</TableCell>
                        <TableCell className="names">Select Topic</TableCell>
                        <TableCell className="names">Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {moduleLIst?.map((module, index) => (
                        <TableRow key={module.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{module.course_name}</TableCell>
                          <TableCell>
                            {isEditing === module.id ? (
                              <input
                                type="text"
                                value={editedModuleName}
                                onChange={(e) =>
                                  setEditedModuleName(e.target.value)
                                }
                              />
                            ) : (
                              module.name
                            )}
                          </TableCell>
                          <TableCell>
                            {isEditing === module.id ? (
                              <>
                                <button
                                  onClick={() => handleEditSave(module.id)}
                                >
                                  Save
                                </button>
                                <button onClick={handleEditCancel}>
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <Edit
                                className="action-update"
                                onClick={() =>
                                  handleEditStart(module.id, module.name)
                                }
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <div onClick={() => handleModuleClick(module.id)}>
                              <QueuePlayNextIcon className="action-update" />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div>
                              <DeleteIcon
                                className="action-delete"
                                onClick={() =>
                                  handleDelete(module.id, module.name)
                                }
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="add-course">
                  <div>
                    {" "}
                    <input
                      className="form-input-course"
                      type="text"
                      placeholder="Enter Module Name"
                      value={newCourseModule}
                      onChange={(e) => setNewCourseModule(e.target.value)}
                    />
                  </div>
                  <div>
                    {" "}
                    <button className="button-3" onClick={handleAddModule}>
                      Add Module
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CourseModule;
