import React, { useState } from "react";
import "./style.css";
import BackgroundImage from "../../assets/images/background.jpg";
import Logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdAccountCircle } from "react-icons/md";
import store from "../../store";
const Login = () => {
  const navigate = useNavigate();
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = async (e) => {
    e.preventDefault();

    const data = {
      username: emailOrUsername.includes("@") ? "" : emailOrUsername,
      email: emailOrUsername.includes("@") ? emailOrUsername : "",
      password: password,
    };
    try {
      const response = await fetch("https://api.artvilleacademy.com/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const data = await response.json();
        toast.success("Login successful");
        const serializedData = JSON.stringify(data);
        sessionStorage.setItem("userDetails", serializedData);
        console.log(serializedData);
        navigate("/dashboard");
      } else {
        toast.error("Login failed");
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("Error during login");
    }
  };
  return (
    <div className="login-div">
      <div className="background-image-div">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <img className="background-image" src={BackgroundImage} alt="Image" />
      </div>
      <div className="navbar-div">
        <div className="navbar-div-item">
          <img className="logo" src={Logo} alt="Logo" />
          <div className="navbar-items">
            <div
              className="navbar-text"
              onClick={() => {
                navigate("/about-us");
              }}
            >
              About us
            </div>
            <div
              className="navbar-text"
              onClick={() => {
                navigate("/feedback");
              }}
            >
              Feedback
            </div>
          </div>
        </div>
      </div>
      <div className="login-form-div">
        <div className="login-icon-div">
          <div>
            {" "}
            <MdAccountCircle size={"30"} />
          </div>
          <div>
            <h2 className="login-head">Login</h2>
          </div>
        </div>

        <form className="login-form" onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="email">Email or Username</label>
            <input
              className="form-group-input"
              id="email"
              placeholder="   Email or Username"
              required
              value={emailOrUsername}
              onChange={(e) => setEmailOrUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              className="form-group-input"
              type="password"
              id="password"
              placeholder="   Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button className="glow-on-hove">Login</button>
        </form>
      </div>
      {/* <div>Copyright</div> */}
    </div>
  );
};

export default Login;
