import React, { useState, useEffect } from "react";
import "./style.css";
import "react-toastify/dist/ReactToastify.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import { Edit } from "@mui/icons-material";
import PaymentModal from "./paymentModal";

const PaymentLinks = () => {
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [courseList, setCourseList] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectId, setSelectId] = useState("");
  const [selectName, setSelectName] = useState("");

  const openComponent = (name, id) => {
    setIsOpen(true);
    setSelectName(name);
    setSelectId(id);
  };
  useEffect(() => {
    setOpen(true);
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/courses",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              access_level: parsedData?.access_level,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCourseList(data.courses);
          setOpen(false);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOpen(false);
      }
    };

    fetchData();
  }, [refreshData]);
  return (
    <>
      <>
        <div className="Teacher-main-div">
          {open ? (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="names">Sl No</TableCell>
                      <TableCell className="names">Course Name</TableCell>
                      <TableCell className="names">Add or Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {courseList?.map((course, index) => (
                      <TableRow key={course.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{course.name}</TableCell>
                        <TableCell>
                          <Edit
                            onClick={() => {
                              openComponent(course.name, course.id);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
        {isOpen && (
          <PaymentModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectId={selectId}
            selectName={selectName}
          />
        )}
      </>
    </>
  );
};

export default PaymentLinks;
