import React, { useEffect, useState } from "react";
import "./style.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { Input, ListItem, Select } from "@mui/material";
import SwipeRightIcon from "@mui/icons-material/SwipeRight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AttendanceList from "./AttendanceList";
import DatePicker from "react-datepicker";
import { format } from "date-fns";

const Attendance = ({ setSelectedDate, selectedDate }) => {
  console.log(selectedDate,"<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>");
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [open, setOpen] = useState(false);
  const [selectBranch, setSelectBranch] = useState();
  const [branchData, setBranchData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [selectBatchTime, setSelectBatchTime] = useState();
  const [selectBatchDays, setSelectBatchDays] = useState();
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setOpen(true);
    const listStudents = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/teacher/list_student_attendance",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: parsedData.user_id,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          setStudentData(data);
          setOpen(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please try again later.",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOpen(false);
      }
    };
    const listBranches = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/list_branches",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setBranchData(data.branches);
          setOpen(false);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOpen(false);
      }
    };
    listBranches();
    listStudents();
  }, []);

  const filteredData = studentData.filter((student) => {
    return (
      student.branch === selectBranch &&
      student.batch_day === selectBatchDays &&
      student.batch_time === selectBatchTime
    );
  });

  return (
    <>
      <div className="add-course-div">
        <button
          className="button-81"
          onClick={() => {
            setSelectedDate(null);
          }}
        >
          <ArrowBackIcon />
        </button>
      </div>
      <div className="Teacher-main-div">
        {open ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="names">Date</TableCell>
                    <TableCell className="names">Branches</TableCell>
                    <TableCell className="names">Batch Time</TableCell>
                    <TableCell className="names">Batch Day</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        dateFormat="yyyy/MM/dd"
                        className="date-picker"
                      />
                    </TableCell>
                    <TableCell>
                      <select
                        className="select-attendance"
                        required
                        value={selectBranch}
                        onChange={(e) => setSelectBranch(e.target.value)}
                      >
                        <option value="">Select a branch</option>
                        {branchData.map((branch, index) => (
                          <option key={index} value={branch.branch_name}>
                            {branch.branch_name}
                          </option>
                        ))}
                      </select>
                    </TableCell>
                    <TableCell>
                      <select
                        required
                        className="select-attendance"
                        value={selectBatchTime}
                        onChange={(e) => setSelectBatchTime(e.target.value)}
                      >
                        <option value="">Select a batch time</option>
                        {branchData
                          .find((branch) => branch.branch_name === selectBranch)
                          ?.branch_time.map((time, index) => (
                            <option key={index} value={time}>
                              {time}
                            </option>
                          ))}
                      </select>
                    </TableCell>
                    <TableCell>
                      <select
                        required
                        className="select-attendance"
                        value={selectBatchDays}
                        onChange={(e) => setSelectBatchDays(e.target.value)}
                      >
                        <option value="">Select batch days</option>
                        {[
                          "Monday & Tuesday",
                          "Thursday & Friday",
                          "Saturday & Sunday",
                          "Saturday , Sunday , Monday & Tuesday",
                          "Monday , Tuesday , Thursday & Friday",
                          "Thursday , Friday , Saturday & Sunday",
                          "Monday, Tuesday, Thursday, Friday, Saturday & Sunday",
                        ].map((day, index) => (
                          <option key={index} value={day}>
                            {day}
                          </option>
                        ))}
                      </select>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div className="attendance-list">
              {isOpen && (
                <AttendanceList
                  studentData={filteredData}
                  selectedDate={selectedDate ? format(selectedDate, "yyyy-MM-dd") : null}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Attendance;
