import React, { useState } from "react";
import "./style.css";
import BackgroundImage from "../../assets/images/Gallery (5).png";
import Navbar from "../Navbar";

const Feedback = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can perform form submission or validation here
  };

  return (
    <div>
      <Navbar />
      <img className="feedback-img" src={BackgroundImage} alt="background" />
      <div className="feedback-form-div">
        <h2 className="feed-head">Feedback Form</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              placeholder="Name"
              id="name"
              name="name"
              className="feedback-input"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <input
              className="feedback-input"
              placeholder="Email"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <textarea
              id="message"
              className="feedback-input-message"
              placeholder="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
          <button className="glow-on-hove">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Feedback;
