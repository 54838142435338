import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import "./style.css";
import Navbar from "../../components/Navbar";
import store from "../../store";
import { toJS } from "mobx";
import Swal from "sweetalert2";
import { Backdrop, CircularProgress } from "@mui/material";
import { AccountBox } from "@mui/icons-material";
const Studentprofile = () => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [studentData, setStudentData] = useState([]);
  console.log(studentData);
  const [open, setOpen] = useState(false);
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const watchFields = watch();
  const [refreshData, setRefreshData] = useState(false);

  const onSubmit = async () => {
    setOpen(true);
    try {
      const result = await handleSubmit((data) => {
        if (!data.guardian_name) {
          data.guardian_name = "";
        }
        if (!data.guardian_mobile) {
          data.guardian_mobile = "";
        }
        const response = fetch(
          "https://api.artvilleacademy.com/student/update_student_data",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...data,
              user_id: parsedData?.user_id,
              access_level: parsedData?.access_level,
            }),
          }
        );
        Swal.fire({
          icon: "success",
          text: "Updated successfully",
        });
      })();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an error submitting the form!",
      });
    }
    setOpen(false);
    setRefreshData((prevState) => !prevState);
  };

  useEffect(() => {
    setOpen(true);
    const listStudentData = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/student/view_student_data",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: parsedData?.user_id,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          setStudentData(data);
          setOpen(false);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOpen(false);
      }
    };

    listStudentData();
  }, [refreshData]);
  useEffect(() => {
    if (studentData) {
      setValue("email", studentData.email || "");
      setValue("first_name", studentData.first_name || "");
      setValue("last_name", studentData.last_name || "");
      setValue("password", studentData.password || "");
      setValue("username", studentData.username || "");
      setValue("mobile", studentData.mobile || "");
      setValue("guardian_name", studentData.guardian_name || "");
      setValue("guardian_mobile", studentData.guardian_mobile || "");
    }
  }, [studentData, setValue]);



  const daysMapping = {
    1: "Monday & Tuesday",
    2: "Thursday & Friday",
    3: "Saturday & Sunday",
    4: "Saturday , Sunday , Monday & Tuesday",
    5: "Monday , Tuesday , Thursday & Friday",
    6: "Thursday , Friday , Saturday & Sunday",
    7: "Monday, Tuesday, Thursday, Friday, Saturday & Sunday",
  };

  const preferredBatchDays = daysMapping[studentData.selected_days];

  return (
    <div>
      {open ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <div className="form-main-div">
            <div className="form-div">
              <div className="form-div-text">
                <div
                  className="form-div-text-head"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <AccountBox /> Profile
                </div>
                <hr className="line"></hr>
              </div>
              <div className="from-scroll-div">
                <form>
                  <div className="joint-div">
                    <div className="form-text-div">
                      <div className="label-text">First Name</div>
                      <Controller
                        name="first_name"
                        control={control}
                        rules={{ required: "Required felid" }}
                        render={({ field }) => (
                          <input className="form-input" {...field} />
                        )}
                      />
                      {errors.first_name && (
                        <p className="error-code">
                          {errors.first_name.message}
                        </p>
                      )}
                    </div>
                    <div className="form-text-div">
                      <div className="label-text">Last Name</div>
                      <Controller
                        name="last_name"
                        control={control}
                        rules={{ required: "Required felid" }}
                        render={({ field }) => (
                          <input className="form-input" {...field} />
                        )}
                      />
                      {errors.last_name && (
                        <p className="error-code">{errors.last_name.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="joint-div">
                    <div className="form-text-div">
                      <div className="label-text">Email</div>
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: "Required felid",
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Invalid email format",
                          },
                        }}
                        render={({ field }) => (
                          <input className="form-input" {...field} />
                        )}
                      />
                      {errors.email && (
                        <p className="error-code">{errors.email.message}</p>
                      )}
                    </div>

                    <div className="form-text-div">
                      <div className="label-text">Phone number</div>
                      <Controller
                        name="mobile"
                        control={control}
                        rules={{ required: "Required felid" }}
                        render={({ field }) => (
                          <input className="form-input" {...field} type="tel" />
                        )}
                      />
                      {errors.mobile && (
                        <p className="error-code">{errors.mobile.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="joint-div">
                    <div className="form-text-div">
                      <div className="label-text">Guardian's Name</div>
                      <Controller
                        name="guardian_name"
                        control={control}
                        rules={{ required: "Required felid" }}
                        render={({ field }) => (
                          <input className="form-input" {...field} />
                        )}
                      />
                      {errors.guardian_name && (
                        <p className="error-code">
                          {errors.guardian_name.message}
                        </p>
                      )}
                    </div>
                    <div className="form-text-div">
                      <div className="label-text">Guardian's Phone Number</div>
                      <Controller
                        name="guardian_mobile"
                        control={control}
                        rules={{ required: "Required felid" }}
                        render={({ field }) => (
                          <input className="form-input" {...field} type="tel" />
                        )}
                      />
                      {errors.guardian_mobile && (
                        <p className="error-code">
                          {errors.guardian_mobile.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-text-div">
                    <div className="label-text">Password</div>
                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input required className="form-input" {...field} />
                      )}
                    />
                  </div>
                  <div className="form-text-div">
                    <div className="label-text">
                      Branch : {studentData.branch}
                    </div>
                    <div className="form-text-div">
                      <div className="label-text">
                        Course : {studentData.course}
                      </div>
                    </div>
                    <div className="form-text-div">
                      <div className="label-text">
                        Module : {studentData.module}
                      </div>
                    </div>
                  </div>
                  <div className="form-text-div">
                    <div className="label-text">
                      Batch Time : {studentData.batch_time}
                    </div>
                  </div>
                  <div className="form-text-div">
                    <div className="label-text">
                    Preferred Batch Days : {preferredBatchDays}
                    </div>
                  </div>
                  <div className="form-text-div">
                    <div className="label-text">
                      Facilitator : {studentData.facilitator}
                    </div>
                  </div>{" "}
                  <div className="form-text-div">
                    <div className="label-text">
                      Date of join : {studentData.date_of_join}
                    </div>
                  </div>
                </form>
              </div>
              <div className="submit-div">
                <button
                  className="submit"
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Studentprofile;
