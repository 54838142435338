import React, { useState, useEffect } from "react";
import "./style.css";
import "react-toastify/dist/ReactToastify.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import { ApprovalOutlined } from "@mui/icons-material";
import Swal from "sweetalert2";
import TeacherAppove from "./TeacherAppove";
import TeacherApprove from "./TeacherAppove";
import AdminApproval from "./AdminApproval";

const ApproveStudents = () => {
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [students, setStudents] = useState([]);
  const [open, setOpen] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [formId, setFormId] = useState("");

  const openComponent = (id) => {
    setIsOpen(true);
    setFormId(id);
  };
  useEffect(() => {
    setOpen(true);
    const listStudents = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/teacher/list_enrollment_forms",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setStudents(data.enrollment_forms);
          console.log(data.enrollment_forms);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setOpen(false);
    };
    listStudents();
  }, [refreshData]);

  const handleDelete = async (form_id, name) => {
    try {
      const confirmDelete = await Swal.fire({
        title: `Are you sure you want to delete ${name}?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          popup: "my-popup",
        },
        width: 300,
        height: 200,
      });

      if (confirmDelete.isConfirmed) {
        const response = await fetch(
          `https://api.artvilleacademy.com/teacher/delete_enrollment_form`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              form_id: form_id,
              access_level: parsedData?.access_level,
            }),
          }
        );

        if (response.ok) {
          Swal.fire({
            icon: "success",
            text: "Delete successfully",
          });
          setRefreshData((prevState) => !prevState);
        } else {
          throw new Error("Network response was not ok.");
        }
      }
    } catch (error) {
      console.error("Error deleting teacher:", error);
    }
  };

  return (
    <>
      <div className="Teacher-main-div">
        {open ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="names">Sl No.</TableCell>
                    <TableCell className="names">Name</TableCell>
                    <TableCell className="names">Branch</TableCell>
                    <TableCell className="names">Course</TableCell>
                    <TableCell className="names">Approve</TableCell>
                    <TableCell className="names">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students.map((student, index) => (
                    <TableRow key={student.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {`${student.first_name} ${student.last_name}`}
                      </TableCell>
                      <TableCell>{student.location}</TableCell>
                      <TableCell>{student.course}</TableCell>
                      <TableCell>
                        <div className="action-button-student">
                          <div
                            onClick={() => {
                              openComponent(student.form_id);
                            }}
                          >
                            <ApprovalOutlined className="action-update" />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>
                          <DeleteIcon
                            className="action-delete"
                            onClick={() => {
                              handleDelete(
                                student.form_id,
                                `${student.first_name} ${student.last_name}`
                              );
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {isOpen && parsedData?.access_level === "teacher" && (
                <TeacherApprove
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  setRefreshData={setRefreshData}
                  formId={formId}
                />
              )}
              {isOpen && parsedData?.access_level === "admin" && (
                <AdminApproval
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  setRefreshData={setRefreshData}
                  formId={formId}
                />
              )}
            </TableContainer>
          </>
        )}
      </div>
    </>
  );
};

export default ApproveStudents;
