import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import "./style.css";
import { Close } from "@mui/icons-material";

export default function ViewArt({
  fullscreenImage,
  setFullscreenImage,
  image,
  imageDescription,
}) {
  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };
  return (
    <>
      <Dialog
        className="style-div"
        open={fullscreenImage}
        TransitionComponent={Slide}
        keepMounted
        onClose={closeFullscreenImage}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <div
            className="close-button"
            onClick={closeFullscreenImage}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Close />
          </div>
          <h5 style={{ margin: 0, paddingLeft: 210 }}> {image}</h5>
        </DialogTitle>
        <DialogContent>
          <div className="fullscreen-modal">
            <img
            
              className="fullscreen-image"
              src={fullscreenImage}
              alt="fullscreen"
            />
          </div>
          <div className="label-text">
            Description:<p>{imageDescription}</p>
          </div>{" "}
        </DialogContent>
      </Dialog>
    </>
  );
}
