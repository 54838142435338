import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import "./style.css";
import Navbar from "../../components/Navbar";
import store from "../../store";
import { toJS } from "mobx";
import Swal from "sweetalert2";
import { Backdrop, CircularProgress } from "@mui/material";
import StudendModal from "./enrollmentModal";
import StudentModal from "./enrollmentModal";
import Button from "@mui/material/Button";
const StudentEnrollmentForm = () => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [selectedDay, setSelectedDay] = useState([]);
  const [selectedDays, setSelectedDays] = useState();
  const [isOpen, setIsOpen] = useState(false);
  console.log(selectedDays);
  const [branchData, setBranchData] = useState([]);
  console.log(branchData);
  const [open, setOpen] = useState(false);
  const [courseData, setCourseData] = useState();
  const [course, setCourse] = useState([]);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const watchFields = watch();
  const [selectAge, setselectAge] = useState("");

  const openComponent = () => {
    console.log("hiiiiiiiiiiiiiiiii");
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const daysMapping = {
    "Monday & Tuesday": 1,
    "Thursday & Friday": 2,
    "Saturday & Sunday": 3,
    "Saturday , Sunday , Monday & Tuesday": 4,
    "Monday , Tuesday , Thursday & Friday": 5,
    "Thursday , Friday , Saturday & Sunday": 6,
    "Monday, Tuesday, Thursday, Friday, Saturday & Sunday": 7,
  };

  const convertToBackendValue = (selectedDay) => {
    return daysMapping[selectedDay];
  };
  const handleDayChange = (event) => {
    const selectedDay = event.target.value;
    setSelectedDays(selectedDay);
    const backendValue = convertToBackendValue(selectedDay);
    setSelectedDay(backendValue);
  };
  const onSubmit = async () => {
    setOpen(true);
    try {
      if (!termsAccepted) {
        throw new Error("Please accept the terms and conditions.");
      }

      // Rest of your code...
      const result = await handleSubmit((data) => {
        if (!data.guardian_name) {
          data.guardian_name = ""; // Set guardian_name as an empty string
        }
        if (!data.guardian_mobile) {
          data.guardian_mobile = ""; // Set guardian_mobile as an empty string
        }
        const response = fetch(
          "https://api.artvilleacademy.com/enrollment_form",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...data,
              selected_days: selectedDay,
              age: selectAge,
              course: courseData.course_name,
              course_fee_id: courseData.course_fee_id,
            }),
          }
        );

        const dataaa = response.then((response) => {
          if (response.status === 201) {
            Swal.fire({
              icon: "success",
              text: "Successfully submitted",
            });
            const paymentLinkResponse = fetch(
              "https://api.artvilleacademy.com/get_payment_link",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  course_id: courseData.course_fee_id,
                  payment_method: watchFields["payment_method"],
                }),
              }
            );
            const dataa = paymentLinkResponse.then((response) => {
              if (response.status) {
                const paymentLinkData = response.json();
                const data = paymentLinkData.then((data) => {
                  console.log(data);
                  window.location.href = data.payment_link;
                });
              }
            });

            // setOpen(false);
          } else {
            throw new Error("Failed to submit data");
          }
          setOpen(false);
        });
      })();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message || "There was an error submitting the form!",
      }).then(() => {
        // window.location.reload();
      });
      setOpen(false);
    }
  };

  useEffect(() => {
    // const listCourse = async () => {
    //   try {
    //     const response = await fetch(
    //       "https://api.artvilleacademy.com/admin/list_courses_api",
    //       {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );
    //     if (response.ok) {
    //       const data = await response.json();
    //       setCourseData(data.courses);
    //       setOpen(false);
    //     } else {
    //       throw new Error("Network response was not ok.");
    //     }
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //     setOpen(false);
    //   }
    // };

    const listBranches = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/list_branches",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ batch_days: selectedDays }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          setBranchData(data.branches);
          setOpen(false);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOpen(false);
      }
    };

    listBranches();
    // listCourse();
  }, [selectedDay]);

  useEffect(() => {
    const fetchCourseByAge = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/list_course_age",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              age: selectAge,
              branch: watchFields["location"],
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCourse(data.courses);
          setOpen(false);
        }
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOpen(false);
      }
    };

    fetchCourseByAge(); // Call the function initially when the component mounts
  }, [selectAge, watchFields["location"]]); // Add selectAge as a dependency to trigger the effect on its change

  return (
    <div>
      {open ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <div className="form-main-div">
            <div className="form-div">
              <div className="form-div-text">
                <div className="form-div-text-head">
                  Student Enrollment Form
                </div>
                <div className="form-div-text-paragraph">
                  Please fill out the following information to enroll as a
                  student.
                </div>
                <hr className="line"></hr>
              </div>
              <div className="from-scroll-div">
                <form>
                  <div className="joint-div">
                    <div className="form-text-div">
                      <div className="label-text">First Name</div>
                      <Controller
                        name="first_name"
                        control={control}
                        rules={{ required: "Required felid" }}
                        render={({ field }) => (
                          <input className="form-input" {...field} />
                        )}
                      />
                      {errors.first_name && (
                        <p className="error-code">
                          {errors.first_name.message}
                        </p>
                      )}
                    </div>
                    <div className="form-text-div">
                      <div className="label-text">Last Name</div>
                      <Controller
                        name="last_name"
                        control={control}
                        rules={{ required: "Required felid" }}
                        render={({ field }) => (
                          <input className="form-input" {...field} />
                        )}
                      />
                      {errors.last_name && (
                        <p className="error-code">{errors.last_name.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="joint-div">
                    <div className="form-text-div">
                      <div className="label-text">Email</div>
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: "Required felid",
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Invalid email format",
                          },
                        }}
                        render={({ field }) => (
                          <input className="form-input" {...field} />
                        )}
                      />
                      {errors.email && (
                        <p className="error-code">{errors.email.message}</p>
                      )}
                    </div>

                    <div className="form-text-div">
                      <div className="label-text">Phone number</div>
                      <Controller
                        name="mobile"
                        control={control}
                        rules={{ required: "Required felid" }}
                        render={({ field }) => (
                          <input className="form-input" {...field} type="tel" />
                        )}
                      />
                      {errors.mobile && (
                        <p className="error-code">{errors.mobile.message}</p>
                      )}
                    </div>
                  </div>

                  <div className="joint-div">
                    <div className="form-text-div">
                      <div className="label-text">Age</div>
                      <select
                        className="select-form"
                        required
                        value={selectAge}
                        onChange={(e) => setselectAge(e.target.value)}
                      >
                        <option value="">Select a Age</option>
                        <option value="5 to 6">5 to 6</option>
                        <option value="7 to 9">7 to 9</option>
                        <option value="10 to 12">10 to 12</option>
                        <option value="13 to 16">13 to 16</option>
                        <option value="18+">18+</option>
                      </select>
                    </div>
                    <div className="form-text-div">
                      <div className="label-text">Guardian's Name</div>
                      <Controller
                        name="guardian_name"
                        control={control}
                        rules={{ required: "Required felid" }}
                        render={({ field }) => (
                          <input className="form-input" {...field} />
                        )}
                      />
                      {errors.guardian_name && (
                        <p className="error-code">
                          {errors.guardian_name.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="joint-div">
                    <div className="form-text-div">
                      <div className="label-text">Guardian's Phone Number</div>
                      <Controller
                        name="guardian_mobile"
                        control={control}
                        rules={{ required: "Required felid" }}
                        render={({ field }) => (
                          <input className="form-input" {...field} type="tel" />
                        )}
                      />
                      {errors.guardian_mobile && (
                        <p className="error-code">
                          {errors.guardian_mobile.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="joint-div">
                    <div className="form-text-div">
                      <div className="label-text">Branch</div>
                      <Controller
                        name="location"
                        control={control}
                        rules={{ required: "Required field" }}
                        render={({ field }) => (
                          <select className="select-form" {...field}>
                            <option value="">Select the branch</option>
                            {branchData.map((branch, index) => (
                              <option key={index} value={branch.branch_name}>
                                {branch.branch_name}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                      {errors.location && (
                        <p className="error-code">{errors.location.message}</p>
                      )}
                    </div>
                    <div className="form-text-div">
                      <div className="label-text">Batch Time</div>
                      <Controller
                        name="batch_time"
                        control={control}
                        rules={{ required: "Required field" }}
                        render={({ field }) => (
                          <select className="select-form" {...field}>
                            <option value="">Select a batch time</option>
                            {branchData
                              .find(
                                (branch) =>
                                  branch.branch_name === watchFields["location"]
                              )
                              ?.branch_time.map((time, index) => (
                                <option key={index} value={time}>
                                  {time}
                                </option>
                              ))}
                          </select>
                        )}
                      />

                      {errors.batch_time && (
                        <p className="error-code">
                          {errors.batch_time.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="joint-div">
                    <div className="form-text-div">
                      <div className="label-text">Course</div>

                      <select
                        className="select-form"
                        required
                        value={courseData?.course_id} // Assuming courseData is an object with course_id and course_name properties
                        onChange={(e) => {
                          const selectedIndex = e.target.selectedIndex;
                          const selectedCourse = course[selectedIndex - 1]; // Subtracting 1 to account for the "Select a Course" option
                          setCourseData({
                            course_fee_id: selectedCourse
                              ? selectedCourse.course_id
                              : "",
                            course_name: e.target.value,
                          });
                        }}
                      >
                        <option value="">Select a Course</option>
                        {course?.map((courseObj, index) => (
                          <option key={index} value={courseObj.course_name}>
                            {courseObj.course_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-text-div">
                      <div className="label-text">Payment</div>
                      <Controller
                        name="payment_method"
                        control={control}
                        rules={{ required: "Required felid" }}
                        render={({ field }) => (
                          <select className="select-form" {...field}>
                            <option> Select</option>
                            <option defaultChecked value="full_payment">
                              Full Payment (10% Discount)
                            </option>
                            <option value="quarterly_payment">
                              Quarterly Payment (24 Classes)
                            </option>
                          </select>
                        )}
                      />
                      {errors.payment_method && (
                        <p className="error-code">
                          {errors.payment_method.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="form-batch-div">
                    <div className="label-text">Preferred Batch Days</div>
                    {[
                      "Monday & Tuesday",
                      "Thursday & Friday",
                      "Saturday & Sunday",
                      "Saturday , Sunday , Monday & Tuesday",
                      "Monday , Tuesday , Thursday & Friday",
                      "Thursday , Friday , Saturday & Sunday",
                      "Monday, Tuesday, Thursday, Friday, Saturday & Sunday",
                    ].map((day) => (
                      <div className="days" key={day}>
                        <input
                          type="checkbox"
                          name="preferredBatchDays"
                          value={day}
                          checked={selectedDays === day}
                          onChange={handleDayChange}
                        />
                        <label>{day}</label>
                      </div>
                    ))}
                    {errors.preferredBatchDays && (
                      <p className="error-code">
                        {errors.preferredBatchDays.message}
                      </p>
                    )}
                  </div>

                  <div className="form-text-div">
                    <div className="label-text">Preferred Start Date</div>
                    <Controller
                      name="start_date"
                      control={control}
                      rules={{ required: "Required felid" }}
                      render={({ field }) => (
                        <input className="select-form" {...field} type="date" />
                      )}
                    />
                    {errors.preferredStartDate && (
                      <p className="error-code">
                        {errors.preferredStartDate.message}
                      </p>
                    )}
                  </div>
                  

                  <div className="form-text-div">
                    <div className="label-text">Special Instructions</div>
                    <Controller
                      name="instructions"
                      control={control}
                      render={({ field }) => (
                        <textarea
                          placeholder="Enter any special instructions or notes"
                          className="form-input"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </form>
              </div>
              <div className="form-text-div">
                <div
                  className="terms-checkbox"
                 
                >
                  <input
                    type="checkbox"
                    id="termsCheckbox"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                  />
                  <a  style={{textDecoration:"underline"}} onClick={() => {
                    openComponent();
                  }} htmlFor="termsCheckbox">
                    I accept the terms and conditions
                  </a>
                </div>
              </div>
              {isOpen && (
                <StudentModal isOpen={isOpen} onClose={handleClose}>
                   <Button onClick={handleClose} style={{marginLeft:"95%"}}>X</Button>
                  {" "}
                  <iframe style={{width:"100%", height:"97%",overflow:"hidden",border:"none"}}
                    src="https://www.artvilleacademy.com/terms-and-conditions"
                    title="Terms and Conditions"
                  ></iframe>
                </StudentModal>
              )}
              <div className="submit-div">
                <button
                  className="submit"
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StudentEnrollmentForm;
