import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "./style.css";

const TeacherOverview = () => {
  const [open, setOpen] = useState(false);
  const [fetchData, setFetchData] = useState([]);
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchStudent, setFetchStudent] = useState([]);

  useEffect(() => {
    const date = new Date();
    const monthNumber = date.getMonth() + 1;
    setCurrentPage(monthNumber);
    console.log(currentPage);
  }, []);
  useEffect(() => {
    setOpen(true);
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/teacher/teacher_dashboard",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: parsedData.user_id,
              target_month: currentPage,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setFetchData(data.teacher_dashboard_data);
          setOpen(false);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchStudent = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/students_joined_in_month",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              target_month: currentPage,
              user_id: parsedData.user_id,
              user: parsedData.user_access,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setFetchStudent(data.student_list);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setOpen(false);
    };

    fetchData();
    fetchStudent();
  }, [currentPage]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  return (
    <div className="">
      {open ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <div className="main-card">
            <Card className="card-style">
              <CardContent>
                <Typography
                  sx={{ fontSize: 20 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Name
                </Typography>
                <Typography variant="h5" component="div">
                  {fetchData.teacher_name}
                </Typography>
              </CardContent>
            </Card>
            <Card className="card-style">
              <CardContent>
                <Typography
                  sx={{ fontSize: 20 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Total Students
                </Typography>
                <Typography variant="h5" component="div">
                  {fetchData.total_students_count}
                </Typography>
              </CardContent>
            </Card>{" "}
            <Card className="card-style">
              <CardContent>
                <Typography
                  sx={{ fontSize: 20 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Total Leaves
                </Typography>
                <Typography variant="h5" component="div">
                  {`${fetchData.leaves_applied_count}/${fetchData.leaves}`}
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className="branch-admin-div">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="names">Name</TableCell>
                    <TableCell className="names">Course</TableCell>
                    <TableCell className="names">Branch</TableCell>
                    <TableCell className="names">Date of Join</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fetchStudent.map((branch) =>
                    branch.students_joined_month.map((student) => (
                      <TableRow key={student.name}>
                        <TableCell>{student.name}</TableCell>
                        <TableCell>{student.course}</TableCell>
                        <TableCell>{student.branch}</TableCell>
                        <TableCell>{student.date_of_join}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <div className="pagination-div">
              <Stack spacing={2} className="pagination-con">
                <Pagination
                  count={12}
                  color="primary"
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Stack>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TeacherOverview;
