import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useForm, Controller } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Swal from "sweetalert2";
import "./style.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChangePassword({ isOpen, setIsOpen }) {
  const { control, handleSubmit, watch } = useForm();
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const newPassword = watch("new_password");
  const confirmPass = watch("confirm_password");
  const validatePasswordMatch = () => {
    if (newPassword !== confirmPass) {
      return false;
    }
    return true;
  };
  const onSubmit = async (data) => {
    const isPasswordMatch = validatePasswordMatch();

    if (!isPasswordMatch) {
      handleClose();
      Swal.fire({
        icon: "error",
        text: "Passwords do not match",
      });
      return;
    }

    try {
      const response = await fetch(
        "https://api.artvilleacademy.com/change_password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...data, user_id: parsedData.user_id }),
        }
      );

      if (!response.ok) {
        throw new Error("Error submitting data");
      }
      Swal.fire({
        icon: "success",
        text: "Password updated successfully",
      });
    } catch (error) {
      console.error("Error submitting data:", error.message);
      Swal.fire({
        icon: "error",
        text: "Something went wrong!",
      });
    }
    handleClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update Password"}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-text-div">
              <div className="label-text">Old Password</div>
              <Controller
                name="old_password"
                control={control}
                rules={{ required: "Required field" }}
                render={({ field }) => (
                  <div className="password-input">
                    <input
                      type={showOldPassword ? "text" : "password"}
                      className="form-input"
                      {...field}
                    />
                    {showOldPassword ? (
                      <VisibilityOff
                        style={{ marginBottom: "-7px" }}
                        onClick={toggleOldPasswordVisibility}
                      />
                    ) : (
                      <Visibility
                        style={{ marginBottom: "-7px" }}
                        onClick={toggleOldPasswordVisibility}
                      />
                    )}
                  </div>
                )}
              />
            </div>

            <div className="form-text-div">
              <div className="label-text">New Password</div>
              <Controller
                name="new_password"
                control={control}
                rules={{ required: "Required field" }}
                render={({ field }) => (
                  <div className="password-input">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      className="form-input"
                      {...field}
                    />
                    {showNewPassword ? (
                      <VisibilityOff
                        style={{ marginBottom: "-7px" }}
                        onClick={toggleNewPasswordVisibility}
                      />
                    ) : (
                      <Visibility
                        style={{ marginBottom: "-7px" }}
                        onClick={toggleNewPasswordVisibility}
                      />
                    )}
                  </div>
                )}
              />
            </div>

            <div className="form-text-div">
              <div className="label-text">Confirm Password</div>
              <Controller
                name="confirm_password"
                control={control}
                rules={{ required: "Required field" }}
                render={({ field }) => (
                  <div className="password-input">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-input"
                      {...field}
                    />
                    {showConfirmPassword ? (
                      <VisibilityOff
                        style={{ marginBottom: "-7px" }}
                        onClick={toggleConfirmPasswordVisibility}
                      />
                    ) : (
                      <Visibility
                        style={{ marginBottom: "-7px" }}
                        onClick={toggleConfirmPasswordVisibility}
                      />
                    )}
                  </div>
                )}
              />
            </div>

            <DialogActions>
              <div className="submit-div" type="submit">
                <button className="submit">Update</button>
              </div>
              <div className="submit-div">
                <Button onClick={handleClose}>Cancel</Button>
              </div>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
