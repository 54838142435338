import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import "./style.css";

import { useState } from "react";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AdminApprovalModal({
  isOpen,
  setIsOpen,
  formId,
  setRefreshData,
}) {
  const { control, handleSubmit, setValue } = useForm();

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = async (leaveType) => {
    try {
      const response = await fetch(
        "https://api.artvilleacademy.com/admin/approve_leave",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: formId, pay_or_no_pay: leaveType }),
        }
      );

      if (!response.ok) {
        throw new Error("Error submitting data");
      }
      Swal.fire({
        icon: "success",
        text: "Leave approved successfully",
      });
    } catch (error) {
      console.error("Error submitting data:", error.message);
      Swal.fire({
        icon: "error",
        text: "Something went wrong!",
      });
    }
    handleClose();
    setRefreshData((prevState) => !prevState);
  };

  return (
    <>
      <Dialog
        className="style-div"
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Select leave type"}</DialogTitle>
        <DialogContent>
          <Button
            onClick={() => {
              onSubmit("Paid");
            }}
            className="paid-btn"
          >
            Paid
          </Button>
          <Button
            onClick={() => {
              onSubmit("LOP");
            }}
            className="lop-btn"
          >
            LOP
          </Button>
          <DialogActions>
            <div className="submit-div">
              <Button onClick={handleClose}>Cancel</Button>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
