import { makeAutoObservable } from "mobx";
export default class informationStore {
  rootStore;

  contactDetails = [];
  userDetails = [];

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setContactDetails = (value) => {
    this.contactDetails = value;
  };
  setUserDetails = (value) => {
    this.userDetails = value;
  };
}
