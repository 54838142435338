import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { GrStatusGood } from "react-icons/gr";
import AdminApprovalModal from "./AdminApprovalModal";

const AdminLeaveApproval = () => {
  const [teachers, setTeachers] = useState([]);
  const [open, setOpen] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [formId, setFormId] = useState("");

  const openComponent = (id) => {
    setIsOpen(true);
    setFormId(id);
  };

  useEffect(() => {
    setOpen(true);
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/list_leave",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              access_level: parsedData.user_id,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setTeachers(data.leave_records);
          console.log(data.leave_records);
          setOpen(false);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOpen(false);
      }
    };

    fetchData();
  }, [refreshData]);

  const handleDelete = async (id) => {
    try {
      const confirmDelete = await Swal.fire({
        title: `Are you sure you want to delete ?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          popup: "my-popup",
        },
        width: 300,
        height: 200,
      });

      if (confirmDelete.isConfirmed) {
        const response = await fetch(
          `https://api.artvilleacademy.com//teacher/delete_leave`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: id,
            }),
          }
        );

        if (response.ok) {
          Swal.fire({
            icon: "success",
            text: "Delete successfully",
          });
          setRefreshData((prevState) => !prevState);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to delete. Please try again later.",
          });
        }
      }
    } catch (error) {
      console.error("Error deleting teacher:", error);
    }
  };

  return (
    <>
      <div className="Teacher-main-div">
        {open ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="names">Sl No</TableCell>
                    <TableCell className="names">Name</TableCell>
                    <TableCell className="names">Leave Type</TableCell>
                    <TableCell className="names">No of days</TableCell>
                    <TableCell className="names">Start Date</TableCell>
                    <TableCell className="names">End Date</TableCell>
                    <TableCell className="names">Reason</TableCell>
                    <TableCell className="names">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teachers.map((teacher, index) => (
                    <TableRow key={teacher.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{teacher.teacher_name}</TableCell>
                      <TableCell>{teacher.leave_type}</TableCell>
                      <TableCell>{teacher.number_of_days}</TableCell>
                      <TableCell>{teacher.start_date}</TableCell>
                      <TableCell>{teacher.end_date}</TableCell>
                      <TableCell>{teacher.reason}</TableCell>
                      {teacher.status == "Pending" ? (
                        <TableCell>
                          <GrStatusGood
                            className="action-delete"
                            onClick={() => {
                              openComponent(teacher.id);
                            }}
                          />
                        </TableCell>
                      ) : (
                        <TableCell>{teacher.status}</TableCell>
                      )}

                      <TableCell>
                        <div>
                          <DeleteIcon
                            className="action-delete"
                            onClick={() => handleDelete(teacher.id)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {isOpen && (
                  <AdminApprovalModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    formId={formId}
                    setRefreshData={setRefreshData}
                  />
                )}
              </Table>
            </TableContainer>
            <ToastContainer position="bottom-right" autoClose={3000} />
          </>
        )}
      </div>
    </>
  );
};

export default AdminLeaveApproval;
