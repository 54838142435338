import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import "./style.css";
import { useEffect } from "react";
import store from "../../store";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TeacherUpdateModal({
  isOpen,
  setIsOpen,
  selectedTeacher,
  setRefreshData,
  newData,
}) {
  const { control, handleSubmit, setValue } = useForm();
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  useEffect(() => {
    if (selectedTeacher) {
      setValue("first_name", selectedTeacher.first_name || "");
      setValue("last_name", selectedTeacher.last_name || "");
      setValue("email", selectedTeacher.email || "");
      setValue("mobile", selectedTeacher.mobile || "");
      setValue("total_leaves", selectedTeacher.total_leaves || "");
      setValue("salary", selectedTeacher.salary || "");
      setValue("date_of_join", selectedTeacher.date_of_join || "");
      setValue("username", selectedTeacher.username || "");
      setValue("password", selectedTeacher.password || "");
    }
  }, [selectedTeacher, setValue]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = async (data) => {
    let apiUrl = "https://api.artvilleacademy.com/admin/update_teacher_data";
    let formDataWithUserId = {
      ...data,
      access_level: parsedData?.access_level,
      status: "Active",
    };

    if (newData) {
      apiUrl = "https://api.artvilleacademy.com/admin/add_teacher";
    } else {
      formDataWithUserId = {
        ...formDataWithUserId,
        user_id: selectedTeacher.user_id,
        access_level: parsedData?.access_level,
      };
    }

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formDataWithUserId),
      });

      if (!response.ok) {
        throw new Error("Error submitting data");
      }
      Swal.fire({
        icon: "success",
        text: "Data submitted successfully",
      });
    } catch (error) {
      console.error("Error submitting data:", error.message);
      Swal.fire({
        icon: "error",
        text: "Something went wrong!",
      });
    }
    handleClose();
    setRefreshData((prevState) => !prevState);
  };

  return (
    <>
      <Dialog
        className="style-div"
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Teacher Details"}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="joint-div">
              <div className="form-text-div">
                <div className="label-text">First Name</div>
                <Controller
                  name="first_name"
                  control={control}
                  rules={{ required: "Required felid" }}
                  render={({ field }) => (
                    <input
                      placeholder="First Name"
                      className="form-input"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="form-text-div">
                <div className="label-text">Last Name</div>
                <Controller
                  name="last_name"
                  control={control}
                  rules={{ required: "Required felid" }}
                  render={({ field }) => (
                    <input
                      placeholder="Last Name"
                      className="form-input"
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
            <div className="joint-div">
              <div className="form-text-div">
                <div className="label-text">Email</div>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      className="form-input"
                      {...field}
                      placeholder="Email"
                    />
                  )}
                />
              </div>
              <div className="form-text-div">
                <div className="label-text">Mobile</div>
                <Controller
                  name="mobile"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      className="form-input"
                      {...field}
                      placeholder="Mobile"
                    />
                  )}
                />
              </div>
            </div>
            <div className="joint-div">
              <div className="form-text-div">
                <div className="label-text">Total Leaves</div>
                <Controller
                  name="total_leaves"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      className="form-input"
                      {...field}
                      placeholder="Total Leaves"
                    />
                  )}
                />
              </div>
              <div className="form-text-div">
                <div className="label-text">Salary</div>
                <Controller
                  name="salary"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      className="form-input"
                      {...field}
                      placeholder="Salary"
                    />
                  )}
                />
              </div>
            </div>
            <div className="joint-div">
              <div className="form-text-div">
                <div className="label-text">User Name</div>
                <Controller
                  name="username"
                  control={control}
                  rules={{ required: "Required felid" }}
                  render={({ field }) => (
                    <input
                      placeholder="username"
                      className="form-input"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="form-text-div">
                <div className="label-text">Joining Date</div>
                <Controller
                  name="date_of_join"
                  control={control}
                  render={({ field }) => (
                    <input className="select-forms" {...field} type="date" />
                  )}
                />
              </div>
            </div>
            <div className="form-text-div">
              <div className="label-text">Password</div>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <input className="form-input" {...field} />
                )}
              />
            </div>

            <DialogActions>
              <div className="submit-div" type="submit">
                <button className="submit">
                  {newData ? "Submit" : "Update"}
                </button>
              </div>
              <div className="submit-div">
                <Button onClick={handleClose}>Cancel</Button>
              </div>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
