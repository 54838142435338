import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import "./style.css";
import { useState } from "react";
import { Box, Chip, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function AddBranchAdmin({
  isOpenAdmin,
  setIsOpenAdmin,
  selectedBranch,
  newData,
  setRefreshData,
}) {
  const theme = useTheme();
  const { control, handleSubmit, setValue } = useForm();
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [personName, setPersonName] = useState([]);

  const [names, setNames] = useState([]);
  const handleClose = () => {
    setIsOpenAdmin(false);
  };

  useEffect(() => {
    if (selectedBranch) {
      setValue("email", selectedBranch.email || "");
      setValue("first_name", selectedBranch.first_name || "");
      setValue("last_name", selectedBranch.last_name || "");
      setValue("password", selectedBranch.password || "");
      setValue("username", selectedBranch.username || "");
    }
  }, [selectedBranch, setValue]);
  useEffect(() => {
    const listBranches = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/list_branches",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setNames(data.branches);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    listBranches();
  }, []);
  const onSubmit = async (data) => {
    try {
      const personIds = personName.map((person) => person.branch_id);
      if (newData) {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/add_branch_admin",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...data,
              branches: personIds,
              access_level: "branch_admin",
            }),
          }
        );
        if (!response.ok) {
          throw new Error("Error submitting data");
        }
        Swal.fire({
          icon: "success",
          text: "Data submitted successfully",
        });
      }
      const response = await fetch(
        "https://api.artvilleacademy.com/admin/update_branch_admin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...data,
            branches: personIds,
            access_level: "branch_admin",
            user_id: selectedBranch.user_id,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Error submitting data");
      }
      Swal.fire({
        icon: "success",
        text: "Data submitted successfully",
      });
    } catch (error) {
      console.error("Error submitting data:", error.message);
    }
    handleClose();
    setRefreshData((prevState) => !prevState);
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      <Dialog
        className="style-div"
        open={isOpenAdmin}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Admin"}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="joint-div">
              <div className="form-text-div">
                <div className="label-text">First Name</div>
                <Controller
                  name="first_name"
                  control={control}
                  rules={{ required: "Required felid" }}
                  render={({ field }) => (
                    <input
                      required
                      placeholder="First Name"
                      className="form-input"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="form-text-div">
                <div className="label-text">Last Name</div>
                <Controller
                  name="last_name"
                  control={control}
                  rules={{ required: "Required felid" }}
                  render={({ field }) => (
                    <input
                      required
                      placeholder="Last Name"
                      className="form-input"
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
            <div className="joint-div">
              <div className="form-text-div">
                <div className="label-text">Username</div>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <input
                      required
                      className="form-input"
                      {...field}
                      placeholder="Username"
                    />
                  )}
                />
              </div>
              <div className="form-text-div">
                <div className="label-text">Email</div>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      required
                      className="form-input"
                      {...field}
                      placeholder="Email"
                      type="email"
                    />
                  )}
                />
              </div>
            </div>

            <div className="joint-div">
              <div className="form-text-div">
                <div className="label-text">Password</div>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      required
                      className="form-input"
                      {...field}
                      placeholder="Password"
                    />
                  )}
                />
              </div>
              <div className="form-text-div">
                <div className="label-text">Select Branch</div>
                <Select
                  className="select-div"
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={personName}
                  onChange={handleChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value.branch_id} label={value.branch_name} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {names?.map((name) => (
                    <MenuItem
                      key={name.branch_id}
                      value={name}
                      style={getStyles(name, personName, theme)}
                    >
                      {name.branch_name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>

            <DialogActions>
              <div className="submit-div" type="submit">
                <button className="submit">Submit</button>
              </div>
              <div className="submit-div">
                <Button onClick={handleClose}>Cancel</Button>
              </div>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
