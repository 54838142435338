import React from "react";
import "./style.css";
import Navbar from "../Navbar";
const AboutUs = () => {
  return (
    <div className="about-div">
      <div className="about-container">
        <div className="about-image">
          <div className="about-heading">Crafting Next-Generation</div>
        </div>
        <div>
          <div className="about-div">About Artville Academy!</div>
          <div className="about-text-div">
            <p className="about-text">
              <Navbar />
              Welcome to the vibrant world of Artville Academy, where creativity
              knows no bounds! Nestled in the heart of Bangalore, we take
              immense pride in being a top-notch institution for art education.
              At Artville, we are committed to nurturing the artistic talents of
              individuals of all ages, from novices taking their first steps
              into the art world to seasoned artists seeking to refine their
              craft. Our distinguished team of experienced art instructors
              shares an unwavering passion for teaching and is dedicated to
              guiding our students on their creative journey. We believe that
              every individual has a unique artistic spark, and it is our
              privilege to ignite that flame and help it flourish. Diversity is
              our strength, and we offer a comprehensive range of classes that
              cater to various artistic interests. Whether your heart sings with
              the strokes of a paintbrush, the precision of drawing, the tactile
              joy of pottery, or the sculpting of three-dimensional
              masterpieces, we have a class that suits your creative appetite.
              Additionally, our workshops and special projects provide an avenue
              for exploration and experimentation, ensuring that every artist's
              thirst for knowledge is quenched. At Artville Academy, our
              ultimate objective is to empower our students to hone their
              artistic skills and produce stunning, personally fulfilling works
              of art. We firmly believe in the transformative power of art to
              inspire, uplift, and bring communities together. Our commitment to
              fostering a safe and welcoming environment ensures that each
              student can freely explore their creativity and express themselves
              through the language of art. Thank you for considering Artville
              Academy for your artistic education needs. We eagerly await the
              opportunity to join you on your artistic journey and witness the
              beauty you'll create. Together, let's paint a brighter, more
              colorful future through the transformative art of self-expression.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
