import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import "./style.css";

export default function ArtUploadModal({
  isOpen,
  setIsOpen,
  setRefreshData,
  id,
}) {
  const { control, handleSubmit, setValue } = useForm();
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = async (data) => {
    try {
      if (!id) {
        const formData = new FormData();
        formData.append("image_name", data.image_name);
        formData.append("description", data.description);
        formData.append("file", data.file[0]);
        formData.append("student_id", parsedData?.user_id);

        const response = await fetch(
          "https://api.artvilleacademy.com/add_gallery",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          Swal.fire({
            icon: "success",
            text: "Successfully Uploaded",
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "An error occurred",
          });
        }
      }
      if (id) {
        const formData = new FormData();
        formData.append("image_name", data.image_name);
        formData.append("description", data.description);
        formData.append("file", data.file[0]);
        formData.append("student_id", id);

        const response = await fetch(
          "https://api.artvilleacademy.com/add_gallery",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          Swal.fire({
            icon: "success",
            text: "Successfully Uploaded",
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "An error occurred",
          });
        }
      }
    } catch (error) {
      console.error("error:", error);
    }
    setRefreshData((prevState) => !prevState);
    setIsOpen(false);
  };

  return (
    <>
      <Dialog
        className="style-div"
        open={isOpen}
        TransitionComponent={Slide}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Payment"}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="joint-div">
              <div className="form-text-div">
                <div className="label-text">Image Name</div>
                <Controller
                  name="image_name"
                  control={control}
                  rules={{ required: "Required field" }}
                  render={({ field }) => (
                    <input required className="form-input" {...field} />
                  )}
                />
              </div>
              <div className="form-text-div">
                <div className="label-text">Description</div>
                <Controller
                  name="description"
                  control={control}
                  rules={{ required: "Required field" }}
                  render={({ field }) => (
                    <textarea
                      required
                      className="feedback-input-message"
                      style={{ border: "1px solid #07080821 " }}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
            <div className="joint-div">
              <div className="form-text-div">
                <div className="label-text">Image File</div>
                <input
                  type="file"
                  onChange={(e) => {
                    setValue("file", e.target.files);
                  }}
                />
              </div>
            </div>
            <DialogActions>
              <div className="submit-div" type="submit">
                <button className="submit">Upload</button>
              </div>
              <div className="submit-div">
                <Button onClick={handleClose}>Cancel</Button>
              </div>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
