import { ArrowBack, ArrowForward } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";

const StudentCalendar = () => {
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);

  const [studentData, setStudentData] = useState([]);
  const [studentCard, setStudentCard] = useState([]);
  const [studentOverview, setStudentOverview] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  console.log(studentCard);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const handleNextMonth = () => {
    const nextMonth = (currentMonth + 1) % 12;
    setCurrentMonth(nextMonth);
    fetchData(parsedData?.user_id, nextMonth, currentYear);
  };

  const handlePreviousMonth = () => {
    const prevMonth = (currentMonth - 1 + 12) % 12;
    setCurrentMonth(prevMonth);
    fetchData(parsedData?.user_id, prevMonth, currentYear);
  };

  const fetchData = async (userId, month, year) => {
    try {
      const response = await fetch(
        "https://api.artvilleacademy.com/student/calendar",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: userId,
            current_month: month,
            current_year: year,
          }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        setStudentData(data.calendar);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(parsedData?.user_id, currentMonth, currentYear);
  }, [parsedData?.user_id, currentMonth, currentYear]);
  useEffect(() => {
    const fetchDataStudent = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/student/course_progress",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: parsedData?.user_id,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          setStudentCard(data.course_progress);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchDataOverview = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/student/overview",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: parsedData?.user_id,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          setStudentOverview(data.overview);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchDataOverview();
    fetchDataStudent();
  }, []);
  const getStatusClass = (status) => {
    if (status === "Present") {
      return "green";
    } else if (status === "Absent") {
      return "red";
    } else {
      return "white";
    }
  };

  return (
    <>
      <div className="arrow-btn">
        <div>
          <button className="button-81" onClick={handlePreviousMonth}>
            <ArrowBack />
          </button>
        </div>
        <div className="label-text" style={{ fontSize: 25 }}>
          {new Date(currentYear, currentMonth).toLocaleString("default", {
            month: "long",
          })}{" "}
          {currentYear}
        </div>
        <div>
          <button className="button-81" onClick={handleNextMonth}>
            <ArrowForward />
          </button>
        </div>
      </div>
      <div className="calendar">
        {studentData.map((item, index) => (
          <>
            {" "}
            <div
              key={index}
              className={`calendar-day ${getStatusClass(item.status)}`}
            >
              <span className="date">{item.date}</span>
              <div className="calendar-p">
                {item.status && <span>{item.status}</span>}
              </div>
              <div className="calendar-p">
                {item.topic && <div className="topic">{item.topic}</div>}
              </div>
            </div>
            <Tooltip
              anchorSelect={`.calendar-day:nth-child(${index + 1})`}
              place="top"
            >
              {item.topic}{" "}
            </Tooltip>
          </>
        ))}
      </div>
      <div className="main-card-calender">
        <Card className="card-style-calender">
          <CardContent>
            <Typography
              sx={{ fontSize: 20 }}
              color="text.secondary"
              gutterBottom
            >
              Present
            </Typography>
            <Typography variant="h5" component="div">
              {studentOverview.days_present}
            </Typography>
          </CardContent>
        </Card>
        <Card className="card-style-calender">
          <CardContent>
            <Typography
              sx={{ fontSize: 20 }}
              color="text.secondary"
              gutterBottom
            >
              Absent
            </Typography>
            <Typography variant="h5" component="div">
              {studentOverview.days_absent}
            </Typography>
          </CardContent>
        </Card>{" "}
        <Card className="card-style-calender">
          <CardContent>
            <Typography
              sx={{ fontSize: 20 }}
              color="text.secondary"
              gutterBottom
            >
              Course complete in
            </Typography>
            <Typography variant="h5" component="div">
              {studentOverview.days_left}
            </Typography>
          </CardContent>
        </Card>
        {/* <Card className="card-style-calender">
          <CardContent>
            <Typography
              sx={{ fontSize: 20 }}
              color="text.secondary"
              gutterBottom
            >
              Course progress
            </Typography>
            <Typography variant="h5" component="div">
              {studentOverview.total_days_to_complete}
            </Typography>
          </CardContent>
        </Card> */}
        <Card className="card-style-calender-div">
          <CardContent>
            {/* <Typography
              sx={{ fontSize: 20 }}
              color="text.secondary"
              gutterBottom
            >
              Course progress
            </Typography> */}
            {studentCard.map((course) => (
              <div key={course.course_name} className="course">
                <Typography variant="h6" component="div">
                  Course: {course.course_name} - {course.status}
                </Typography>
                {course.modules.map((module) => (
                  <div key={module.module_name} className="module">
                    <Typography variant="subtitle1" component="div">
                      Module: {module.module_name} - {module.status}
                    </Typography>
                    <div className="topics">
                      {module.topics.map((topic) => (
                        <Typography
                          key={topic.topic_name}
                          variant="body2"
                          component="div"
                        >
                          Topic: {topic.topic_name} - {topic.status}
                        </Typography>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default StudentCalendar;
