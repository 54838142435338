import { Card, CardContent, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import ArtUploadModal from "./ArtUploadModal";
import ViewArt from "./ViewArt";
import { Delete } from "@mui/icons-material";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ArtGallery = ({ id, setGalleryOpen, setID }) => {
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [isOpen, setIsOpen] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [open, setOpen] = useState(false);
  const [galleryData, setGalleryData] = useState();
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [image, setImage] = useState("");
  const [imageDescription, setImageDescription] = useState("");
  console.log(id, "id");
  const openComponent = () => {
    setIsOpen(true);
  };
  const handleDelete = async (id) => {
    try {
      const confirmDelete = await Swal.fire({
        title: `Are you sure you want to delete ?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          popup: "my-popup",
        },
        width: 300,
        height: 200,
      });

      if (confirmDelete.isConfirmed) {
        const response = await fetch(
          `https://api.artvilleacademy.com/delete_image`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              image_id: id,
            }),
          }
        );

        if (response.ok) {
          Swal.fire({
            icon: "success",
            text: "Delete successfully",
          });
          setRefreshData((prevState) => !prevState);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to delete. Please try again later.",
          });
        }
      }
    } catch (error) {
      console.error("Error deleting teacher:", error);
    }
  };

  const openFullscreenImage = (url, image, description) => {
    setFullscreenImage(url);
    setImage(image);
    setImageDescription(description);
  };

  useEffect(() => {
    setOpen(true);
    const fetchData = async () => {
      try {
        if (id == null) {
          const response = await fetch(
            "https://api.artvilleacademy.com/view_gallery",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                student_id: parsedData?.user_id,
              }),
            }
          );
          if (response.ok) {
            const data = await response.json();
            setGalleryData(data.gallery);
            setOpen(false);
          } else {
            throw new Error("Network response was not ok.");
          }
        }
        if (id) {
          const response = await fetch(
            "https://api.artvilleacademy.com/view_gallery",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                student_id: id,
              }),
            }
          );

          if (response.ok) {
            const data = await response.json();
            setGalleryData(data.gallery);
            setOpen(false);
          } else {
            throw new Error("Network response was not ok.");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOpen(false);
      }
    };

    fetchData();
  }, [refreshData]);

  return (
    <>
      {id && (
        <>
          {" "}
          <div className="add-course-div">
            <button
              className="button-81"
              onClick={() => {
                setID(null);
                setGalleryOpen(false);
              }}
            >
              <ArrowBackIcon />
            </button>
          </div>
        </>
      )}
      <div className="main-card">
        {galleryData?.map((item, index) => (
          <Card key={index} className="card-style-image">
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h5 style={{ margin: 0 }}>{item.image_name}</h5>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              ></Typography>
              <div style={{ height: "160px", width: "260px" }}>
                {/* Parent container */}
                <Typography
                  onClick={() =>
                    openFullscreenImage(
                      item.url,
                      item.image_name,
                      item.description
                    )
                  }
                  variant="h4"
                  component="div"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <img
                    src={item.url}
                    alt="image"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "fill",
                      cursor: "pointer",
                    }}
                  />
                </Typography>
              </div>
              <div onClick={() => handleDelete(item.image_id)}>
                <Delete className="action-delete" />
              </div>
            </CardContent>
          </Card>
        ))}
        <div onClick={openComponent}>
          <Card className="card-style-image">
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
                style={{ marginTop: 50 }}
              >
                Upload
              </Typography>
              <Typography variant="h4" component="div">
                +
              </Typography>
            </CardContent>
          </Card>
        </div>
        {isOpen && (
          <ArtUploadModal
            id={id}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setRefreshData={setRefreshData}
          />
        )}
        {fullscreenImage && (
          <ViewArt
            fullscreenImage={fullscreenImage}
            setFullscreenImage={setFullscreenImage}
            imageDescription={imageDescription}
            image={image}
          />
        )}
      </div>
    </>
  );
};

export default ArtGallery;
