import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import "react-toastify/dist/ReactToastify.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import Swal from "sweetalert2";
import AddBranchAdmin from "./AddBranchAdmin";

const AdminBranch = () => {
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [ViewBranch, setViewBranch] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAdmin, setIsOpenAdmin] = useState(false);
  const [newData, setNewData] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [open, setOpen] = useState(false);

  const openComponent = (branch) => {
    setIsOpen(true);
  };
  const openComponentAdmin = (branch) => {
    setIsOpenAdmin(true);
    setSelectedBranch(branch);
  };

  useEffect(() => {
    setOpen(true);
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/view_branch_admin",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setViewBranch(data.branch_admins);
          setOpen(false);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOpen(false);
      }
    };

    fetchData();
  }, [refreshData]);

  const handleUsersClick = () => {};

  const handleDelete = async (id) => {
    try {
      const confirmDelete = await Swal.fire({
        title: `Are you sure you want to delete ?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          popup: "my-popup",
        },
        width: 300,
        height: 200,
      });

      if (confirmDelete.isConfirmed) {
        const response = await fetch(
          `https://api.artvilleacademy.com/admin/delete_branch_admin`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: id,
            }),
          }
        );

        if (response.ok) {
          Swal.fire({
            icon: "success",
            text: "Delete successfully",
          });
          setRefreshData((prevState) => !prevState);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to delete. Please try again later.",
          });
        }
      }
    } catch (error) {
      console.error("Error deleting teacher:", error);
    }
  };
  return (
    <>
      <>
        <div className="add-teacher-div">
          <button
            className="button-83"
            onClick={() => {
              openComponentAdmin();
              setNewData(true);
            }}
          >
            Add Branch Admin
          </button>
          {isOpenAdmin && (
            <AddBranchAdmin
              setIsOpenAdmin={setIsOpenAdmin}
              isOpenAdmin={isOpenAdmin}
              selectedBranch={selectedBranch}
              newData={newData}
              setRefreshData={setRefreshData}
              setSelectedBranch={setSelectedBranch}
            />
          )}
        </div>

        <div className="Teacher-main-div">
          {open ? (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="names">Sl No</TableCell>
                      <TableCell className="names">Name</TableCell>
                      <TableCell className="names">View/Edit Branch</TableCell>
                      <TableCell className="names">Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ViewBranch?.map((users, index) => (
                      <TableRow key={users.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {" "}
                          {`${users.first_name} ${users.last_name}`}
                        </TableCell>

                        <TableCell>
                          <div
                            style={{ paddingLeft: "30px" }}
                            onClick={() => {
                              openComponentAdmin(users);
                              setNewData(false);
                            }}
                          >
                            <QueuePlayNextIcon className="action-update" />
                          </div>
                        </TableCell>

                        <TableCell>
                          <div style={{ paddingLeft: "10px" }}>
                            <DeleteIcon
                              className="action-delete"
                              onClick={() => handleDelete(users.user_id)}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
      </>
    </>
  );
};

export default AdminBranch;
