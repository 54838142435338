import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import "./style.css";
import { useState } from "react";
import { Box, Chip, MenuItem, OutlinedInput } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import Select from "react-dropdown-select";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddBranch({
  isOpen,
  setIsOpen,
  newData,
  setRefreshData,
  selectedBranch,
}) {
  const { control, handleSubmit, setValue } = useForm();
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [names, setNames] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (selectedBranch) {
      setValue("branch_name", selectedBranch.branch_name || "");
      setValue("fixed_dividend", selectedBranch.fixed_dividend || "");
      setValue("fixed_expenses", selectedBranch.fixed_expenses || "");
      setValue("maintenance", selectedBranch.maintenance || "");
      setValue("misc_expenses", selectedBranch.misc_expenses || "");
      setValue("rent", selectedBranch.rent || "");
    }
    const initialSelectedNames = selectedBranch?.users?.map((teacher) => ({
      label: teacher.email,
      value: teacher.id,
    }));
    setSelectedNames(initialSelectedNames);
  }, [selectedBranch, setValue]);

  const onSubmit = async (data) => {
    try {
      const personIds = selectedNames.map((person) => person.value);
      if (newData) {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/add_branch",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...data, teachers: personIds }),
          }
        );

        if (!response.ok) {
          throw new Error("Error submitting data");
        }
        Swal.fire({
          icon: "success",
          text: "Data submitted successfully",
        });
      }
      const response = await fetch(
        "https://api.artvilleacademy.com/admin/update_branch",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...data,
            teachers: personIds,
            branch_id: selectedBranch.branch_id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Error submitting data");
      }
      Swal.fire({
        icon: "success",
        text: "Updated successfully",
      });
    } catch (error) {
      console.error("Error submitting data:", error.message);
    }
    handleClose();
    setRefreshData((prevState) => !prevState);
  };

  useEffect(() => {
    const ListEmail = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/list_users_api",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setNames(data.users);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    ListEmail();
  }, []);
  const handleChange = (selectedOptions) => {
    setSelectedNames(selectedOptions);
  };

  return (
    <>
      <Dialog
        className="style-div"
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Branch Details"}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="joint-div">
              <div className="form-text-div">
                <div className="label-text">Branch Name</div>
                <Controller
                  name="branch_name"
                  control={control}
                  rules={{ required: "Required felid" }}
                  render={({ field }) => (
                    <input
                      required
                      placeholder="Branch Name"
                      className="form-input"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="form-text-div">
                <div className="label-text">Maintenance</div>
                <Controller
                  name="maintenance"
                  control={control}
                  rules={{ required: "Required felid" }}
                  render={({ field }) => (
                    <input
                      required
                      placeholder="Maintenance"
                      className="form-input"
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
            <div className="joint-div">
              <div className="form-text-div">
                <div className="label-text">Miscellaneous Expenses</div>
                <Controller
                  name="misc_expenses"
                  control={control}
                  render={({ field }) => (
                    <input
                      required
                      className="form-input"
                      {...field}
                      placeholder="Miscellaneous Expenses"
                    />
                  )}
                />
              </div>
              <div className="form-text-div">
                <div className="label-text">Rent</div>
                <Controller
                  name="rent"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      required
                      className="form-input"
                      {...field}
                      placeholder="Rent"
                    />
                  )}
                />
              </div>
            </div>
            <div className="joint-div">
              <div className="form-text-div">
                <div className="label-text">Fixed Expense</div>
                <Controller
                  name="fixed_expenses"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      required
                      className="form-input"
                      {...field}
                      placeholder="Fixed Expense"
                    />
                  )}
                />
              </div>
              <div className="form-text-div">
                <div className="label-text">Dividend</div>
                <Controller
                  name="fixed_dividend"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      required
                      className="form-input"
                      {...field}
                      placeholder="Dividend"
                    />
                  )}
                />
              </div>
            </div>
            <div className="joint-div">
              <div className="form-text-div">
                <div className="label-text">
                  Add Users For Mail Notifications
                </div>
                {/* <Select
                  className="select-div"
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  required
                  value={personName}
                  onChange={handleChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value.id} label={value.email} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {names?.map((name) => (
                    <MenuItem
                      key={name.id}
                      value={name}
                      style={getStyles(name, personName, theme)}
                    >
                      {name.email}
                    </MenuItem>
                  ))}
                </Select> */}
                <div className="filter-names">
                  <Select
                    className="filter-select"
                    placeholder=""
                    multi
                    options={names.map((name) => ({
                      label: name.email,
                      value: name.id,
                    }))}
                    onChange={handleChange}
                    values={selectedNames}
                  />
                </div>
              </div>
            </div>

            <DialogActions>
              <div className="submit-div" type="submit">
                <button className="submit">
                  {newData ? "Submit" : "Update"}
                </button>
              </div>
              <div className="submit-div">
                <Button onClick={handleClose}>Cancel</Button>
              </div>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
