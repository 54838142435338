import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "./style.css";

const AdminOverview = () => {
  const [open, setOpen] = useState(false);
  const [fetchData, setFetchData] = useState([]);
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectBranch, setSelectBranch] = useState("all");
  const [names, setNames] = useState();
  const [fetchStudent, setFetchStudent] = useState([]);
  const [branchData, setBranchData] = useState([]);

  useEffect(() => {
    const date = new Date();
    const monthNumber = date.getMonth() + 1;
    setCurrentPage(monthNumber);
  }, []);

  useEffect(() => {
    setOpen(true);
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/admin_dashboard",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setBranchData(data.branch_data_list);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchStudentData = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/students_joined_in_month",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              target_month: currentPage,
              user: parsedData.user_access,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setFetchStudent(data.student_list);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setOpen(false);
    };

    const fetchBranches = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/list_branches",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setNames(data.branches);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    fetchStudentData();
    fetchBranches();
  }, [currentPage, parsedData.user_access]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page); // Update currentPage when page changes
  };

  const handleBranchChange = (e) => {
    setSelectBranch(e.target.value); // Update selected branch
  };

  const filteredBranchData = branchData.filter((branch) => {
    if (selectBranch === "all") {
      return branch.branch_name === "All"; // Filter only for 'All' branch
    } else {
      return branch.branch_name === selectBranch; // Show data for selected branch only
    }
  });

  const filteredStudentData = selectBranch === "all"
    ? fetchStudent
    : fetchStudent.filter(student => student.branch_name === selectBranch);

  return (
    <div className="">
      {open ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <div className="admin-branch">
            <select
              className="select-forms"
              required
              value={selectBranch}
              onChange={handleBranchChange}
            >
              <option value="all">All</option>
              {names?.map((branch, index) => (
                <option key={index} value={branch.branch_name}>
                  {branch.branch_name}
                </option>
              ))}
            </select>
          </div>
          {filteredBranchData.map((detail, index) => (
            <div className="main-card" key={index}>
              <Card className="card-style">
                <CardContent>
                  <Typography
                    sx={{ fontSize: 20 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Active students
                  </Typography>
                  <Typography variant="h5" component="div">
                    {detail.total_active_students_count}
                  </Typography>
                </CardContent>
              </Card>
              <Card className="card-style">
                <CardContent>
                  <Typography
                    sx={{ fontSize: 20 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Active Teachers
                  </Typography>
                  <Typography variant="h5" component="div">
                    {detail.total_active_teachers_count}
                  </Typography>
                </CardContent>
              </Card>
              <Card className="card-style">
                <CardContent>
                  <Typography
                    sx={{ fontSize: 20 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Total Revenue
                  </Typography>
                  <Typography variant="h5" component="div">
                    {detail.total_revenue}
                  </Typography>
                </CardContent>
              </Card>
              <Card className="card-style">
                <CardContent>
                  <Typography
                    sx={{ fontSize: 20 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Total Expenses
                  </Typography>
                  <Typography variant="h5" component="div">
                    {detail.total_expenses}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          ))}
          <div className="branch-admin-div">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="names">Name</TableCell>
                    <TableCell className="names">Course</TableCell>
                    <TableCell className="names">Branch</TableCell>
                    <TableCell className="names">Date of Join</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredStudentData.flatMap(branch => branch.students_joined_month).map((student, index) => (
                    <TableRow key={index}>
                      <TableCell>{student.name}</TableCell>
                      <TableCell>{student.course}</TableCell>
                      <TableCell>{student.branch}</TableCell>
                      <TableCell>{student.date_of_join}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="pagination-div">
            <Stack spacing={2} className="pagination-con">
              <Pagination
                count={12}
                color="primary"
                page={currentPage}
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminOverview;
