import React, { useState, useEffect } from "react";
import "./style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Edit } from "@mui/icons-material";

const ModuleTopics = ({ setTopicOpen, topicId }) => {
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [topicList, setTopicList] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [open, setOpen] = useState(false);

  const [newModuleTopic, setNewModuleTopic] = useState("");
  const [newModuleTopicDays, setNewModuleTopicDays] = useState("");
  const [isEditing, setIsEditing] = useState(null);
  const [editedTopicName, setEditedTopicName] = useState("");
  const [editedTopicDays, setEditedTopicDays] = useState("");

  const handleAddTopic = async () => {
    try {
      setOpen(true);

      const response = await fetch(
        "https://api.artvilleacademy.com/admin/add_topic",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: newModuleTopic,
            days_to_complete: newModuleTopicDays,
            module_id: topicId,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setNewModuleTopic("");
        setNewModuleTopicDays("");
        setRefreshData((prevState) => !prevState);
        setOpen(false);
        Swal.fire({
          icon: "success",
          text: "Topic added successfully",
        });
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error adding Topic:", error);
      setOpen(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add Topic. Please try again later.",
      });
    }
  };
  
  useEffect(() => {
    setOpen(true);
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/topics",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              access_level: parsedData?.access_level,
              module_id: topicId,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setTopicList(data.topics);
          setOpen(false);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOpen(false);
      }
    };

    fetchData();
  }, [refreshData]);

  const handleEditStart = (id, name, days) => {
    setIsEditing(id);
    setEditedTopicName(name);
    setEditedTopicDays(days);
  };

  const handleEditCancel = () => {
    setIsEditing(null);
    setEditedTopicName("");
    setEditedTopicDays("");
  };

  const handleEditSave = async (id) => {
    try {
      setOpen(true);
      const response = await fetch(`https://api.artvilleacademy.com/admin/update/topic`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          topic_id: id,
          name: editedTopicName,
          days_to_complete: editedTopicDays,
        }),
      });

      if (response.ok) {
        setRefreshData((prevState) => !prevState); // Refresh the topic list
        setIsEditing(null);
        setEditedTopicName("");
        setEditedTopicDays("");
        setOpen(false);
        Swal.fire({
          icon: "success",
          text: "Topic updated successfully",
        });
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error updating topic:", error);
      setOpen(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update topic. Please try again later.",
      });
    }
  };

  const handleDelete = async (id, name) => {
    try {
      const confirmDelete = await Swal.fire({
        title: `Are you sure you want to delete ${name}?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          popup: "my-popup",
        },
        width: 300,
        height: 200,
      });

      if (confirmDelete.isConfirmed) {
        const response = await fetch(
          `https://api.artvilleacademy.com/admin/topics/delete`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              topic_id: id,
              access_level: parsedData?.access_level,
            }),
          }
        );

        if (response.ok) {
          Swal.fire({
            icon: "success",
            text: "Delete successfully",
          });
          setRefreshData((prevState) => !prevState);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to delete. Please try again later.",
          });
        }
      }
    } catch (error) {
      console.error("Error deleting teacher:", error);
    }
  };

  return (
    <>
      <div className="add-course-div">
        <button
          className="button-81"
          onClick={() => {
            setTopicOpen(false);
          }}
        >
          <ArrowBackIcon />
        </button>
      </div>
      <div className="Teacher-main-div">
        {open ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="names">Sl No</TableCell>
                    <TableCell className="names">Course Name</TableCell>
                    <TableCell className="names"> Module Name</TableCell>
                    <TableCell className="names">Topics</TableCell>
                    <TableCell className="names">No of Days</TableCell>
                    <TableCell className="names">Edit</TableCell>
                    <TableCell className="names">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topicList?.map((topic, index) => (
                    <TableRow key={topic.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{topic.course_name}</TableCell>
                      <TableCell>{topic.module_name}</TableCell>
                      <TableCell>
                        {isEditing === topic.id ? (
                          <input
                            type="text"
                            value={editedTopicName}
                            onChange={(e) => setEditedTopicName(e.target.value)}
                          />
                        ) : (
                          topic.name
                        )}
                      </TableCell>
    
                      <TableCell>
                        {isEditing === topic.id ? (
                          <input
                            type="number"
                            value={editedTopicDays}
                            onChange={(e) => setEditedTopicDays(e.target.value)}
                          />
                        ) : (
                          topic.days_to_complete
                        )}
                      </TableCell>
                      <TableCell>
                        {isEditing === topic.id ? (
                          <>
                            <button onClick={() => handleEditSave(topic.id)}>Save</button>
                            <button onClick={handleEditCancel}>Cancel</button>
                          </>
                        ) : (
                          <Edit
                            className="action-update"
                            onClick={() =>
                              handleEditStart(topic.id, topic.name, topic.days_to_complete)
                            }
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <div>
                          <DeleteIcon
                            className="action-delete"
                            onClick={() => handleDelete(topic.id, topic.name)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="add-course">
              <div>
                {" "}
                <input
                  className="form-input-course"
                  type="text"
                  placeholder="Enter Topic Name"
                  value={newModuleTopic}
                  onChange={(e) => setNewModuleTopic(e.target.value)}
                />
              </div>
              <div>
                {" "}
                <input
                  className="form-input-course"
                  type="number"
                  placeholder="No of days"
                  value={newModuleTopicDays}
                  onChange={(e) => setNewModuleTopicDays(e.target.value)}
                />
              </div>
              <div>
                {" "}
                <button className="button-3" onClick={handleAddTopic}>
                  Add Topic
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ModuleTopics;
