import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import "./style.css";
import { useState } from "react";
import { useEffect } from "react";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AdminApproval({
  isOpen,
  setIsOpen,
  setRefreshData,
  formId,
}) {
  const { control, handleSubmit, setValue } = useForm();
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [cashType, setCashType] = useState("");
  const [teacherData, setTeacherData] = useState([]);
  const [selectedFacilitator, setSelectedFacilitator] = useState();

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = async () => {
    try {
      const response = await fetch(
        "https://api.artvilleacademy.com/admin/approve_student",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: selectedFacilitator,
            cash_type: cashType,
            form_id: formId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Error submitting data");
      }

      const responseData = await response.json();
      const { revenue } = responseData;
      Swal.fire({
        icon: "success",
        text: `This student has generated a revenue of ${revenue} for the current month. `,
      });
    } catch (error) {
      console.error("Error submitting data:", error.message);
      Swal.fire({
        icon: "error",
        text: "Something went wrong!",
      });
    }

    handleClose();
    setRefreshData((prevState) => !prevState);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/list_teachers_api",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              access_level: parsedData?.access_level,
              form_id: formId,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setTeacherData(data.teachers);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <Dialog
        className="style-div"
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="joint-div">
              <div className="form-text-div">
                <div className="label-text">Payment Method</div>
                <select
                  className="form-input"
                  required
                  value={cashType}
                  onChange={(e) => setCashType(e.target.value)}
                >
                  <option value="">Payment Method</option>
                  <option value="card">Card</option>
                  <option value="cash">Cash</option>
                </select>
              </div>
              <div className="form-text-div">
                <div className="label-text">Facilitator</div>
                <select
                  className="form-input"
                  required
                  value={selectedFacilitator}
                  onChange={(e) => setSelectedFacilitator(e.target.value)}
                >
                  <option value="">Select a facilitator</option>
                  {teacherData.map((teacher) => (
                    <option key={teacher.id} value={teacher.user_id}>
                      {teacher.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <DialogActions>
              <div className="submit-div" type="submit">
                <button className="submit">Submit</button>
              </div>
              <div className="submit-div">
                <Button onClick={handleClose}>Cancel</Button>
              </div>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
