import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./style.css";
import Attendance from ".";
import moment from "moment";

function AttendanceCalender() {
  const [selectedDate, setSelectedDate] = useState(null);
  const handleCalendarChange = (newValue) => {
    const formatted = moment(newValue).format("YYYY-MM-DD");
    setSelectedDate(formatted);
  };

  return (
    <>
      {selectedDate ? (
        <Attendance
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      ) : (
        <div className="calendar-div">
          <Calendar onChange={handleCalendarChange} value={selectedDate} />
        </div>
      )}
    </>
  );
}

export default AttendanceCalender;
