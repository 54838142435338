import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import "./style.css";
import { useEffect } from "react";
import { useState } from "react";
import { Backdrop, CircularProgress, Select } from "@mui/material";
import store from "../../store";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StudentUpdateModal({
  isOpen,
  setIsOpen,
  selectedStudent,
  setRefreshData,
  newData,
  branchData,
}) {
  const { control, handleSubmit, setValue, watch } = useForm();
  const [selectedDays, setSelectedDays] = useState([
    selectedStudent?.days_attending,
  ]);
  const [selectedDay, setSelectedDay] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [selectCourse, setselectCourse] = useState(selectedStudent?.course);
  const [selectModule, setselectModule] = useState(selectedStudent?.module);
  const [selectAge, setselectAge] = useState(selectedStudent?.age);
  const [selectPayment, setSelectPayment] = useState(
    selectedStudent?.payment_method
  );
  const [teacherData, setTeacherData] = useState([]);
  const [selectStatus, setselectStatus] = useState(selectedStudent?.status);
  const [selectBranch, setSelectBranch] = useState(
    selectedStudent?.branch || ""
  );
  const [selectBatchTime, setSelectBatchTime] = useState(
    selectedStudent?.batch_time || ""
  );

  const [selectedFacilitator, setSelectedFacilitator] = useState(
    selectedStudent?.facilitator
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (selectedStudent) {
      setValue("first_name", selectedStudent.first_name || "");
      setValue("last_name", selectedStudent.last_name || "");
      setValue("email", selectedStudent.email || "");
      setValue("mobile", selectedStudent.mobile || "");
      setValue("age", selectedStudent.age || "");
      setValue("payment", selectedStudent.payment || "");
      setValue("username", selectedStudent.username || "");
      setValue("batch_time", selectedStudent.batch_time || "");
      setValue("branch", selectedStudent.branch || "");
      setValue("guardian_mobile", selectedStudent.guardian_mobile || "");
      setValue("guardian_name", selectedStudent.guardian_name || "");
      setValue("revenue_per_month", selectedStudent.revenue_per_month || "");
      setValue("password", selectedStudent.password || "");
      setValue("date_of_join", selectedStudent.date_of_join || "");
      setSelectedDays(selectedStudent?.days_attending);
    }
  }, [selectedStudent, setValue]);

  const daysMapping = {
    "Monday & Tuesday": 1,
    "Thursday & Friday": 2,
    "Saturday & Sunday": 3,
    "Saturday , Sunday , Monday & Tuesday": 4,
    "Monday , Tuesday , Thursday & Friday": 5,
    "Thursday , Friday , Saturday & Sunday": 6,
    "Monday, Tuesday, Thursday, Friday, Saturday & Sunday": 7,
  };

  const convertToBackendValue = (selectedDay) => {
    return daysMapping[selectedDay];
  };
  useEffect(() => {
    setOpen(true);
    const listCourse = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/list_courses_api",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setCourseData(data.courses);
          setOpen(false);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOpen(false);
      }
    };
    const listTeachers = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/list_teachers_api",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setTeacherData(data.teachers);
          setOpen(false);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOpen(false);
      }
    };

    listTeachers();
    listCourse();
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedDays(selectedStudent?.days_attending);
    const backendValue = convertToBackendValue(selectedStudent?.days_attending);
    setSelectedDay(backendValue);
  }, [selectedStudent]);
  const handleDayChange = (event) => {
    const selectedDay = event.target.value;
    setSelectedDays(selectedDay);
    const backendValue = convertToBackendValue(selectedDay);
    setSelectedDay(backendValue);
  };

  console.log(selectedDay);

  const onSubmit = async (data) => {
    if (!data.guardian_name) {
      data.guardian_name = ""; // Set guardian_name as an empty string
    }
    if (!data.guardian_mobile) {
      data.guardian_mobile = ""; // Set guardian_mobile as an empty string
    }
    let apiUrl = "https://api.artvilleacademy.com/admin/update_student_data";
    let formDataWithUserId = {
      ...data,
      access_level: "admin",
      days_attending: selectedDay.toString(),
      facilitator: selectedFacilitator,
      course: selectCourse,
      module: selectModule,
      age: selectAge,
      payment: selectPayment,
      status: selectStatus,
      branch: selectBranch,
      batch_time: selectBatchTime,
    };

    if (newData) {
      apiUrl = "https://api.artvilleacademy.com/admin/add_student";
    } else {
      formDataWithUserId = {
        ...formDataWithUserId,
        user_id: selectedStudent.user_id,
      };
    }

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formDataWithUserId),
      });

      if (!response.ok) {
        throw new Error("Error submitting data");
      }
      Swal.fire({
        icon: "success",
        text: "Data submitted successfully",
      });
    } catch (error) {
      console.error("Error submitting data:", error.message);
      Swal.fire({
        icon: "error",
        text: "Something went wrong!",
      });
    }
    handleClose();
    setRefreshData((prevState) => !prevState);
  };

  return (
    <>
      {open ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Dialog
          className="style-div"
          open={isOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Student Details"}</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="joint-div">
                <div className="form-text-div">
                  <div className="label-text">First Name</div>
                  <Controller
                    name="first_name"
                    control={control}
                    rules={{ required: "Required felid" }}
                    render={({ field }) => (
                      <input
                        required
                        placeholder="First Name"
                        className="form-input"
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className="form-text-div">
                  <div className="label-text">Last Name</div>
                  <Controller
                    name="last_name"
                    control={control}
                    rules={{ required: "Required felid" }}
                    render={({ field }) => (
                      <input
                        required
                        placeholder="Last Name"
                        className="form-input"
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="joint-div">
                <div className="form-text-div">
                  <div className="label-text">Email</div>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        required
                        className="form-input"
                        {...field}
                        placeholder="Email"
                      />
                    )}
                  />
                </div>
                <div className="form-text-div">
                  <div className="label-text">User Name</div>
                  <Controller
                    name="username"
                    control={control}
                    rules={{ required: "Required felid" }}
                    render={({ field }) => (
                      <input
                        required
                        placeholder="username"
                        className="form-input"
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="joint-div">
                <div className="form-text-div">
                  <div className="label-text">Mobile</div>
                  <Controller
                    name="mobile"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        required
                        className="form-input"
                        {...field}
                        placeholder="Phone number"
                      />
                    )}
                  />
                </div>
                <div className="form-text-div">
                  <div className="label-text">Facilitator</div>
                  <select
                    className="form-input"
                    required
                    value={selectedFacilitator}
                    onChange={(e) => setSelectedFacilitator(e.target.value)}
                  >
                    <option value="">Select a facilitator</option>
                    {teacherData.map((teacher) => (
                      <option key={teacher.id} value={teacher.id}>
                        {teacher.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="joint-div">
                <div className="form-text-div">
                  <div className="label-text">Password</div>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <input
                        required
                        className="form-input"
                        {...field}
                        placeholder="Password"
                      />
                    )}
                  />
                </div>
                <div className="form-text-div">
                  <div className="label-text">Revenue collected this month</div>
                  <Controller
                    name="revenue_per_month"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        required
                        className="form-input"
                        {...field}
                        placeholder="Revenue collected this month"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="joint-div">
                <div className="form-text-div">
                  <div className="label-text">Age</div>
                  <select
                    className="form-input"
                    required
                    value={selectAge}
                    onChange={(e) => setselectAge(e.target.value)}
                  >
                    <option value="">Select a Age</option>
                    <option value="5 to 6">5 to 6</option>
                    <option value="7 to 9">7 to 9</option>
                    <option value="10 to 12">10 to 12</option>
                    <option value="13 to 16">13 to 16</option>
                    <option value="18+">18+</option>
                  </select>
                </div>
                <div className="form-text-div">
                  <div className="label-text">Course</div>
                  <select
                    required
                    className="form-input"
                    value={selectCourse}
                    onChange={(e) => setselectCourse(e.target.value)}
                  >
                    <option value="">Select a Course</option>
                    {courseData.map((course) => (
                      <option key={course.course_id} value={course.course_name}>
                        {course.course_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="joint-div">
                <div className="form-text-div">
                  <div className="label-text">Branch</div>
                  <select
                    className="form-input"
                    required
                    value={selectBranch}
                    onChange={(e) => setSelectBranch(e.target.value)}
                  >
                    <option value="">Select a branch</option>
                    {branchData.map((branch, index) => (
                      <option key={index} value={branch.branch_name}>
                        {branch.branch_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-text-div">
                  <div className="label-text">Module</div>
                  <select
                    required
                    className="form-input"
                    value={selectModule}
                    onChange={(e) => setselectModule(e.target.value)}
                  >
                    <option value="">Select a module</option>
                    {courseData
                      .find((course) => course.course_name === selectCourse)
                      ?.modules.map((module) => (
                        <option
                          key={module.module_id}
                          value={module.module_name}
                        >
                          {module.module_name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="joint-div">
                <div className="form-text-div">
                  <div className="label-text">Status</div>
                  <select
                    required
                    className="form-input"
                    value={selectStatus}
                    onChange={(e) => setselectStatus(e.target.value)}
                  >
                    <option value="">Select a Status</option>
                    <option value="Active">Active</option>
                    <option value="Finished">Finished</option>
                    <option value="Break">Break</option>
                    <option value="Discontinued">Discontinued</option>
                  </select>
                </div>
                <div className="form-text-div">
                  <div className="label-text">Batch time</div>
                  <select
                    required
                    className="form-input"
                    value={selectBatchTime}
                    onChange={(e) => setSelectBatchTime(e.target.value)}
                  >
                    <option value="">Select a batch time</option>
                    {branchData
                      .find((branch) => branch.branch_name === selectBranch)
                      ?.branch_time.map((time, index) => (
                        <option key={index} value={time}>
                          {time}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="joint-div">
                <div className="form-text-div">
                  <div className="label-text">Guardian name</div>
                  <Controller
                    name="guardian_name"
                    control={control}
                    render={({ field }) => (
                      <input
                        className="form-input"
                        {...field}
                        placeholder="Guardian name"
                      />
                    )}
                  />
                </div>
                <div className="form-text-div">
                  <div className="label-text">Guardian mobile</div>
                  <Controller
                    name="guardian_mobile"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        className="form-input"
                        {...field}
                        placeholder="Guardian mobile"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="joint-div">
                <div className="form-text-div">
                  <div className="label-text">Date of join</div>
                  <Controller
                    name="date_of_join"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        required
                        className="select-form"
                        {...field}
                        type="date"
                        style={{ width: "284px" }}
                      />
                    )}
                  />
                </div>
                <div className="form-text-div">
                  <div className="label-text">Payment</div>
                  <select
                    className="form-input"
                    required
                    value={selectPayment}
                    onChange={(e) => setSelectPayment(e.target.value)}
                  >
                    <option value="">Select payment method</option>
                    {/* <option value="monthly_payment">Monthly payment</option> */}
                    <option value="quarterly_payment">Quarterly payment</option>
                    <option value="full_payment">Full payment</option>
                  </select>
                </div>
              </div>

              <div className="joint-div">
                <div className="form-batch-div">
                  <div className="label-text">Preferred Batch Days</div>
                  {[
                    "Monday & Tuesday",
                    "Thursday & Friday",
                    "Saturday & Sunday",
                    "Saturday , Sunday , Monday & Tuesday",
                    "Monday , Tuesday , Thursday & Friday",
                    "Thursday , Friday , Saturday & Sunday",
                    "Monday, Tuesday, Thursday, Friday, Saturday & Sunday",
                  ].map((day) => (
                    <div className="days" key={day}>
                      <input
                        type="checkbox"
                        name="preferredBatchDays"
                        value={day}
                        checked={selectedDays === day}
                        onChange={handleDayChange}
                      />
                      <label>{day}</label>
                    </div>
                  ))}
                </div>
              </div>

              <DialogActions>
                <div className="submit-div" type="submit">
                  <button className="submit">
                    {newData ? "Submit" : "Update"}
                  </button>
                </div>
                <div className="submit-div">
                  <Button onClick={handleClose}>Cancel</Button>
                </div>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
