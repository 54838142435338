import { createContext, useContext } from "react";
import informationStore from "./Information";

class RootStore {
  constructor() {
    // this.experienceStore = new experienceStore(this);
    // this.appStore = new appStore(this);
    this.informationStore = new informationStore(this);
  }
}

const rootStore = new RootStore();

export default {
  //   workFlowStore: rootStore.workFlowStore,
  //   experienceStore: rootStore.experienceStore,
  //   appStore: rootStore.appStore,
  informationStore: rootStore.informationStore,
};

export const StoreContext = createContext(rootStore);

export const useStore = () => useContext(StoreContext);
