import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "./style.css";
const BranchAdmin = () => {
  const [open, setOpen] = useState(false);
  const [fetchData, setFetchData] = useState([]);
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const date = new Date();
    const monthNumber = date.getMonth() + 1;
    setCurrentPage(monthNumber);
  }, []);
  useEffect(() => {
    setOpen(true);
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/branch_admin/students_joined_in_month",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: parsedData.user_id,
              target_month: currentPage,
              user: parsedData.user_access,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setFetchData(data.student_list);
          setOpen(false);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOpen(false);
      }
    };
    setOpen(false);
    fetchData();
  }, [currentPage]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page); // Update currentPage when page changes
  };
  return (
    <div className="branch-admin-div">
      <Backdrop open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="names">Name</TableCell>
              <TableCell className="names">Course</TableCell>
              <TableCell className="names">Date of Join</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchData.map((branch) =>
              branch.students_joined_month.map((student) => (
                <TableRow key={student.name}>
                  <TableCell>{student.name}</TableCell>
                  <TableCell>{student.course}</TableCell>
                  <TableCell>{student.date_of_join}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="page-div">
        <div></div>
        <div className="pagination-div">
          <Stack spacing={2} className="pagination-con">
            <Pagination
              count={12}
              color="primary"
              page={currentPage}
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default BranchAdmin;
