import React, { useState, useEffect } from "react";
import "./style.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import Swal from "sweetalert2";
import CourseModule from "./CourseModule";
import { Edit } from "@mui/icons-material";

const Courses = () => {
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [isOpen, setIsOpen] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [newCourseName, setNewCourseName] = useState("");
  const [isEditing, setIsEditing] = useState(null);
  const [editedCourseName, setEditedCourseName] = useState("");

  const handleAddCourse = async () => {
    try {
      setOpen(true);

      const response = await fetch(
        "https://api.artvilleacademy.com/admin/add_course",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: newCourseName,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setNewCourseName(""); // Clear the input field after successful addition
        setRefreshData((prevState) => !prevState); // Refresh the course list
        setOpen(false);
        Swal.fire({
          icon: "success",
          text: "Course added successfully",
        });
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error adding course:", error);
      setOpen(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add course. Please try again later.",
      });
    }
  };

  useEffect(() => {
    setOpen(true);
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/courses",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              access_level: parsedData?.access_level,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCourseList(data.courses);
          setOpen(false);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOpen(false);
      }
    };

    fetchData();
  }, [refreshData]);

  const handleEditStart = (id, name) => {
    setIsEditing(id);
    setEditedCourseName(name);
  };

  const handleEditCancel = () => {
    setIsEditing(null);
    setEditedCourseName("");
  };

  const handleEditSave = async (id) => {
    try {
      setOpen(true);
      const response = await fetch(`https://api.artvilleacademy.com/admin/update/course`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          course_id: id,
          name: editedCourseName,
        }),
      });

      if (response.ok) {
        setRefreshData((prevState) => !prevState); // Refresh the course list
        setIsEditing(null);
        setEditedCourseName("");
        setOpen(false);
        Swal.fire({
          icon: "success",
          text: "Course updated successfully",
        });
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error updating course:", error);
      setOpen(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update course. Please try again later.",
      });
    }
  };

  const handleModuleClick = (id) => {
    setIsOpen(true);
    setSelectedId(id);
  };

  const handleDelete = async (id, name) => {
    try {
      const confirmDelete = await Swal.fire({
        title: `Are you sure you want to delete ${name}?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          popup: "my-popup",
        },
        width: 300,
        height: 200,
      });

      if (confirmDelete.isConfirmed) {
        const response = await fetch(
          `https://api.artvilleacademy.com/admin/courses/delete`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              course_id: id,
              access_level: parsedData?.access_level,
            }),
          }
        );

        if (response.ok) {
          Swal.fire({
            icon: "success",
            text: "Delete successfully",
          });
          setRefreshData((prevState) => !prevState);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to delete. Please try again later.",
          });
        }
      }
    } catch (error) {
      console.error("Error deleting teacher:", error);
    }
  };

  return (
    <>
      {isOpen ? (
        <CourseModule
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          selectedId={selectedId}
        />
      ) : (
        <>
          <div className="Teacher-main-div">
            {open ? (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="names">Sl No</TableCell>
                        <TableCell className="names">Course Name</TableCell>
                        <TableCell className="names">Edit</TableCell>
                        <TableCell className="names">Select Module</TableCell>
                        <TableCell className="names">Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {courseList?.map((course, index) => (
                        <TableRow key={course.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {isEditing === course.id ? (
                              <input
                                type="text"
                                value={editedCourseName}
                                onChange={(e) => setEditedCourseName(e.target.value)}
                              />
                            ) : (
                              course.name
                            )}
                          </TableCell>
                          <TableCell>
                            {isEditing === course.id ? (
                              <>
                                <button onClick={() => handleEditSave(course.id)}>Save</button>
                                <button onClick={handleEditCancel}>Cancel</button>
                              </>
                            ) : (
                              <Edit
                                className="action-update"
                                onClick={() => handleEditStart(course.id, course.name)}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <div onClick={() => handleModuleClick(course.id)}>
                              <QueuePlayNextIcon className="action-update" />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div>
                              <DeleteIcon
                                className="action-delete"
                                onClick={() => handleDelete(course.id, course.name)}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="add-course">
                  <div>
                    {" "}
                    <input
                      className="form-input-course"
                      type="text"
                      placeholder="Enter Course Name"
                      value={newCourseName}
                      onChange={(e) => setNewCourseName(e.target.value)}
                    />
                  </div>
                  <div>
                    {" "}
                    <button className="button-3" onClick={handleAddCourse}>
                      Add Course
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Courses;
