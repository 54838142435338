import React, { useEffect, useState } from "react";
import "./style.css";
import "react-toastify/dist/ReactToastify.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Swal from "sweetalert2";

const AttendanceList = ({ studentData, selectedDate }) => {
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [open, setOpen] = useState(false);
  const [topics, setTopics] = useState({});
  const [alignment, setAlignment] = useState({});
  const handleTopicChange = (studentId, topicValue) => {
    setTopics({ ...topics, [studentId]: topicValue });
  };
  const handleAlignmentChange = (studentId, alignmentValue) => {
    setAlignment({ ...alignment, [studentId]: alignmentValue });
  };
  const handleAttendanceSubmit = async () => {
    const students = studentData.map((student, index) => ({
      
      user_id: student.user_id,
      topic: topics[student.user_id] || "", // Get the topic for the specific student
      status: alignment[student.user_id] || "na",
      module: studentData[0].module,
      course: studentData[0].course,
      date: selectedDate,
      module:student.module,
      course:student.course
    }));
    if (students.length) {
      try {
        setOpen(true);
        const response = await fetch(
          "https://api.artvilleacademy.com/teacher/mark_student_attendance",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ students:students}),
          }
        );

        if (response.ok) {
          Swal.fire({
            icon: "success",
            text: "Attendance marked successfully",
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "Failed to send attendance data",
          });
          console.error("Failed to send attendance data");
        }
      } catch (error) {
        console.error("Error sending attendance data:", error);
      } finally {
        setOpen(false);
      }
    }
  };
  return (
    <>
      <div className="Teacher-main-div">
        {open ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="names">Sl no</TableCell>

                    <TableCell className="names">Names</TableCell>
                    <TableCell className="names"> Topics</TableCell>
                    <TableCell className="names">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentData.map((student, index) => (
                    <TableRow key={student.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {`${student.first_name} ${student.last_name}`}
                      </TableCell>
                      <TableCell>
                        <select
                          required
                          className="select-attendance"
                          value={topics[student.user_id] || ""}
                          onChange={(e) =>
                            handleTopicChange(student.user_id, e.target.value)
                          }
                        >
                          <option value="">Select Topics</option>
                          {student?.topic?.map((time, index) => (
                            <option key={index} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                      </TableCell>
                      <TableCell>
                        <ToggleButtonGroup
                          value={alignment[student.user_id] || "na"}
                          exclusive
                          onChange={(e, newAlignment) =>
                            handleAlignmentChange(student.user_id, newAlignment)
                          }
                          className="toggle-div"
                        >
                          <ToggleButton
                            className="toggle-btn"
                            value="present"
                            style={{
                              backgroundColor:
                                alignment[student.user_id] === "present"
                                  ? "#43ce18c4"
                                  : "",
                            }}
                          >
                            Present
                          </ToggleButton>
                          <ToggleButton
                            className="toggle-btn"
                            value="na"
                            style={{
                              backgroundColor:
                                alignment[student.user_id] === "na"
                                  ? "#ffb200b3"
                                  : "",
                            }}
                          >
                            Na
                          </ToggleButton>
                          <ToggleButton
                            className="toggle-btn"
                            value="absent"
                            style={{
                              backgroundColor:
                                alignment[student.user_id] === "absent"
                                  ? "#ff2400"
                                  : "",
                            }}
                          >
                            Absent
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="add-submit">
              {" "}
              <button className="button-3" onClick={handleAttendanceSubmit}>
                Submit
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AttendanceList;
