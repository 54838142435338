import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import "./style.css";

import { useState } from "react";
import { useEffect } from "react";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PaymentModal({
  isOpen,
  setIsOpen,
  selectId,
  selectName,
}) {
  const { control, handleSubmit, setValue } = useForm();
  const [selectAge, setselectAge] = useState("");
  const [selectMode, setSelectMode] = useState("");
  const [next, setNext] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    if (next && data && data.length > 0) {
      const course = data[0];
      setValue("quarterly_fee", course.quarterly_fee);
      setValue("quarterly_payment_link", course.quarterly_payment_link);
      setValue("monthly_fee", course.monthly_fee);
      setValue("monthly_payment_link", course.monthly_payment_link);
      setValue("one_time_payment", course.one_time_payment);
      setValue("one_time_payment_link", course.one_time_payment_link);
    }
  }, [data, next, setValue]);

  const handleClose = () => {
    setIsOpen(false);
  };
  const handlePayment = async () => {
    try {
      const payload = {
        age: selectAge,
        mode_of_learning: selectMode,
        course_id: selectId,
        course_name: selectName,
      };

      const response = await fetch(
        "https://api.artvilleacademy.com/list_course_age",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setData(data.courses);
        setNext(true);
      }
    } catch (error) {
      console.error("Payment error:", error);
    }
  };
  const onSubmit = async (data) => {
    console.log(data, "<<<<<<<<<>>>>>>");
    try {
      const payload = {
        age: selectAge,
        mode_of_learning: selectMode,
        course_id: selectId,
        course_name: selectName,
      };

      const response = await fetch(
        "https://api.artvilleacademy.com/update_course_age",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...payload, ...data }),
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          text: "Successfully Updated",
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "An error occurred",
        });
      }
    } catch (error) {
      console.error("Payment error:", error);
    }
    setIsOpen(false);
  };
  return (
    <>
      <Dialog
        className="style-div"
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Payment"}</DialogTitle>
        {next === false ? (
          <DialogContent>
            <div className="joint-div">
              <div className="form-text-div">
                <div className="label-text">Age</div>
                <select
                  className="select-form"
                  required
                  value={selectAge}
                  onChange={(e) => setselectAge(e.target.value)}
                >
                  <option value="">Select a Age</option>
                  <option value="5 to 6">5 to 6</option>
                  <option value="7 to 9">7 to 9</option>
                  <option value="10 to 12">10 to 12</option>
                  <option value="13 to 16">13 to 16</option>
                  <option value="18+">18+</option>
                </select>
              </div>
              <div className="form-text-div">
                <div className="label-text">Mode of learning</div>
                <select
                  className="select-form"
                  required
                  value={selectMode}
                  onChange={(e) => setSelectMode(e.target.value)}
                >
                  <option value="">Mode of learning</option>
                  <option value="Online">Online</option>
                  <option value="Offline">Offline</option>
                </select>
              </div>
            </div>
            <DialogActions>
              <div className="submit-div" type="submit">
                <button onClick={handlePayment} className="submit">
                  Next
                </button>
              </div>
              <div className="submit-div">
                <Button onClick={handleClose}>Cancel</Button>
              </div>
            </DialogActions>
          </DialogContent>
        ) : (
          <DialogContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="joint-div">
                <div className="form-text-div">
                  <div className="label-text">Quarterly Payment</div>
                  <Controller
                    name="quarterly_fee"
                    control={control}
                    rules={{ required: "Required felid" }}
                    render={({ field }) => (
                      <input required className="form-input" {...field} />
                    )}
                  />
                </div>
                <div className="form-text-div">
                  <div className="label-text">Quarterly Payment Link</div>
                  <Controller
                    name="quarterly_payment_link"
                    control={control}
                    rules={{ required: "Required felid" }}
                    render={({ field }) => (
                      <input required className="form-input" {...field} />
                    )}
                  />
                </div>
              </div>
              <div className="joint-div">
                <div className="form-text-div">
                  <div className="label-text">Monthly Payment</div>
                  <Controller
                    name="monthly_fee"
                    control={control}
                    rules={{ required: "Required felid" }}
                    render={({ field }) => (
                      <input required className="form-input" {...field} />
                    )}
                  />
                </div>
                <div className="form-text-div">
                  <div className="label-text">Monthly Payment Link</div>
                  <Controller
                    name="monthly_payment_link"
                    control={control}
                    rules={{ required: "Required felid" }}
                    render={({ field }) => (
                      <input required className="form-input" {...field} />
                    )}
                  />
                </div>
              </div>{" "}
              <div className="joint-div">
                <div className="form-text-div">
                  <div className="label-text">Full Payment</div>
                  <Controller
                    name="one_time_payment"
                    control={control}
                    rules={{ required: "Required felid" }}
                    render={({ field }) => (
                      <input required className="form-input" {...field} />
                    )}
                  />
                </div>
                <div className="form-text-div">
                  <div className="label-text">Full Payment Link</div>
                  <Controller
                    name="one_time_payment_link"
                    control={control}
                    rules={{ required: "Required felid" }}
                    render={({ field }) => (
                      <input required className="form-input" {...field} />
                    )}
                  />
                </div>
              </div>
              <DialogActions>
                <div className="submit-div" type="submit">
                  <button className="submit">Submit</button>
                </div>
                <div className="submit-div">
                  <Button onClick={handleClose}>Cancel</Button>
                </div>
              </DialogActions>
            </form>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}
