import React, { useState, useEffect } from "react";
import "./style.css";
import "react-toastify/dist/ReactToastify.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  BrowseGallery,
  BrowseGallerySharp,
  Edit,
  EventRepeat,
  Payment,
  Photo,
} from "@mui/icons-material";
import Swal from "sweetalert2";
import StudentUpdateModal from "./StudentUpdateModal";
import { LinearProgress } from "@mui/material";
import store from "../../store";
import ArtGallery from "../StudentsLogin/ArtGallery";

const Students = () => {
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [students, setStudents] = useState([]);

  const [open, setOpen] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [newData, setNewData] = useState(false);
  const [selectStatus, setselectStatus] = useState("");
  const [branchData, setBranchData] = useState([]);
  const [selectBranch, setSelectBranch] = useState("");
  const [selectBatchTime, setSelectBatchTime] = useState("");
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [id, setID] = useState();
  const [toggleStates, setToggleStates] = useState({});
  console.log(toggleStates);
  const toggleButton = (user_id, currentColor) => {
    const newActiveState = !toggleStates[user_id]?.active;
    const newColor = newActiveState ? "green" : "red";

    // Update payment status based on toggle state
    updatePayment(user_id, newActiveState);

    const newState = {
      ...toggleStates,
      [user_id]: {
        active: newActiveState,
        color: newColor,
      },
    };
    setToggleStates(newState);
  };

  const initializeToggleStates = (students) => {
    const initialState = {};
    students.forEach((student) => {
      initialState[student.user_id] = {
        active: student.payment_status, // Initialize toggle state based on payment_status
        color: student.payment_status ? "green" : "red", // Initialize color based on payment_status
      };
    });
    setToggleStates(initialState);
  };

  useEffect(() => {
    // setOpen(true);
    initializeToggleStates(students); // Initialize toggle states when component mounts
  }, [students]);

  const updatePayment = async (user_id, paymentStatus) => {
    try {
      const response = await fetch(
        "https://api.artvilleacademy.com/admin/update_payment_status",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: user_id,
            payment_status: paymentStatus,
          }),
        }
      );
      if (response.ok) {
        const data = await response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setOpen(false);
    }
  };

  const openComponent = (student) => {
    setSelectedStudent(student);
    setIsOpen(true);
  };
  const handleOpen = (id) => {
    setID(id);
    setGalleryOpen(true);
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredStudents, setFilteredStudents] = useState([]);
  useEffect(() => {
    const filteredByStatus = students.filter((student) =>
      selectStatus
        ? student.status.toLowerCase() === selectStatus.toLowerCase()
        : true
    );

    const filteredByBranch = filteredByStatus.filter((student) =>
      selectBranch
        ? student.branch.toLowerCase() === selectBranch.toLowerCase()
        : true
    );

    const filteredByBatchTime = filteredByBranch.filter((student) =>
      selectBatchTime ? student.batch_time === selectBatchTime : true
    );

    const filteredByName = filteredByBatchTime.filter((student) =>
      `${student.first_name} ${student.last_name}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );

    setFilteredStudents(filteredByName);
  }, [searchQuery, students, selectStatus, selectBranch, selectBatchTime]);

  useEffect(() => {
    const listBranches = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/list_branches",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setBranchData(data.branches);
          setOpen(false);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOpen(false);
      }
    };
    listBranches();
    setOpen(false);
  }, []);

  useEffect(() => {
    setOpen(true);
    const fetchStudentData = async () => {
      try {
        const apiUrl =
          parsedData?.access_level === "teacher"
            ? "https://api.artvilleacademy.com/teacher/list_students" // Replace with your API endpoint
            : "https://api.artvilleacademy.com/admin/list_students";

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            access_level: parsedData?.access_level,
            teacher_id: parsedData.user_id,
          }),
        });
        if (response.ok) {
          const data = await response.json();
          setStudents(data.students);
          setOpen(false);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOpen(false);
      }
    };

    fetchStudentData();
  }, [refreshData]);

  const handleDelete = async (user_id, name) => {
    try {
      const confirmDelete = await Swal.fire({
        title: `Are you sure you want to delete ${name}?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          popup: "my-popup",
        },
        width: 300,
        height: 200,
      });

      if (confirmDelete.isConfirmed) {
        const response = await fetch(
          `https://api.artvilleacademy.com/admin/delete_student`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: user_id,
              access_level: parsedData?.access_level,
            }),
          }
        );

        if (response.ok) {
          Swal.fire({
            icon: "success",
            text: "Delete successfully",
          });
          setRefreshData((prevState) => !prevState);
        } else {
          throw new Error("Network response was not ok.");
        }
      }
    } catch (error) {
      console.error("Error deleting teacher:", error);
    }
  };

  return (
    <>
      {galleryOpen === true ? (
        <>
          <ArtGallery id={id} setGalleryOpen={setGalleryOpen} setID={setID} />
        </>
      ) : (
        <>
          {" "}
          <div className="add-teacher-div">
            <button
              className="button-83"
              onClick={() => {
                openComponent();
                setNewData(true);
              }}
            >
              Add Students
            </button>
            {isOpen && (
              <StudentUpdateModal
                isOpen={isOpen}
                newData={newData}
                setNewData={setNewData}
                setIsOpen={setIsOpen}
                selectedStudent={selectedStudent}
                setRefreshData={setRefreshData}
                branchData={branchData}
              />
            )}
          </div>
          <div className="filters">
            <div className="search-container">
              <input
                style={{ width: 180 }}
                className="select-forms"
                type="text"
                placeholder="Search by name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="filter-container">
              <select
                style={{ width: 180 }}
                className="form-input"
                value={selectStatus}
                onChange={(e) => setselectStatus(e.target.value)}
              >
                <option value="">Select a Status</option>
                <option value="Active">Active</option>
                <option value="Finished">Finished</option>
                <option value="Break">Break</option>
                <option value="Discontinued">Discontinued</option>
              </select>
            </div>
            <div>
              <select
                className="form-input"
                style={{ width: 180 }}
                value={selectBranch}
                onChange={(e) => setSelectBranch(e.target.value)}
              >
                <option value="">Select a branch</option>
                {branchData.map((branch, index) => (
                  <option key={index} value={branch.branch_name}>
                    {branch.branch_name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <select
                style={{ width: 180 }}
                className="form-input"
                value={selectBatchTime}
                onChange={(e) => setSelectBatchTime(e.target.value)}
              >
                <option value="">Select a batch time</option>
                {branchData
                  .find((branch) => branch.branch_name === selectBranch)
                  ?.branch_time.map((time, index) => (
                    <option key={index} value={time}>
                      {time}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="Teacher-main-div">
            {open ? (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      {parsedData?.access_level == "admin" ? (
                        <TableRow>
                          <TableCell className="names">Sl No.</TableCell>
                          <TableCell className="names">Name</TableCell>
                          <TableCell className="names">Facilitator</TableCell>
                          <TableCell className="names">Branch</TableCell>
                          <TableCell className="names">Time</TableCell>
                          <TableCell className="names">Course</TableCell>
                          <TableCell className="names">Module</TableCell>
                          <TableCell className="names">Mobile</TableCell>
                          <TableCell className="names">Status</TableCell>
                          <TableCell className="names">DOJ</TableCell>
                          <TableCell className="names">
                            Payment method
                          </TableCell>
                          <TableCell className="names">
                            Payment status
                          </TableCell>
                          <TableCell className="names"></TableCell>
                          <TableCell className="names"></TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell className="names">Sl No.</TableCell>
                          <TableCell className="names">Name</TableCell>
                          <TableCell className="names">Course</TableCell>
                          <TableCell className="names">Module</TableCell>
                          <TableCell className="names">Status</TableCell>
                          <TableCell className="names">Branch</TableCell>
                          <TableCell className="names">DOJ</TableCell>
                          <TableCell className="names">Gallery</TableCell>
                          <TableCell className="names">
                            Payment method
                          </TableCell>
                          <TableCell className="names">
                            Payment status
                          </TableCell>
                          <TableCell className="names"></TableCell>
                          <TableCell className="names"></TableCell>
                        </TableRow>
                      )}
                    </TableHead>
                    {parsedData?.access_level == "admin" ? (
                      <TableBody>
                        {filteredStudents.map((student, index) => (
                          <TableRow key={student.id}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {`${student.first_name} ${student.last_name}`}
                            </TableCell>
                            <TableCell>{student.facilitator}</TableCell>
                            <TableCell>{student.branch}</TableCell>
                            <TableCell>{student.batch_time}</TableCell>
                            <TableCell>{student.course}</TableCell>
                            <TableCell>{student.module}</TableCell>
                            <TableCell>{student.mobile}</TableCell>
                            <TableCell>{student.status}</TableCell>
                            <TableCell>{student.date_of_join}</TableCell>

                            <TableCell>
                              {student.payment_method === "full_payment"
                                ? "Full payment"
                                : student.payment_method === "monthly_payment"
                                ? "Monthly payment"
                                : student.payment_method === "quarterly_payment"
                                ? "Quarterly payment"
                                : student.payment_method}
                            </TableCell>

                            <TableCell>
                              <div key={index}>
                                <div
                                  className={`toggle-btn ${
                                    toggleStates[student.user_id]?.active
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    toggleButton(
                                      student.user_id,
                                      toggleStates[student.user_id]?.color ||
                                        (student.payment_status
                                          ? "green"
                                          : "red")
                                    )
                                  }
                                  style={{
                                    backgroundColor:
                                      toggleStates[student.user_id]?.color ||
                                      (student.payment_status
                                        ? "green"
                                        : "red"),
                                  }}
                                >
                                  <div className="toggle-handle"></div>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="action-button-student">
                                <div
                                  onClick={() => {
                                    openComponent(student);
                                    setNewData(false);
                                  }}
                                >
                                  <Edit className="action-update" />
                                </div>

                                <div>
                                  <DeleteIcon
                                    className="action-delete"
                                    onClick={() => {
                                      handleDelete(
                                        student.user_id,
                                        `${student.first_name} ${student.last_name}`
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        {filteredStudents.map((student, index) => (
                          <TableRow key={student.id}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {`${student.first_name} ${student.last_name}`}
                            </TableCell>
                            <TableCell>{student.course}</TableCell>
                            <TableCell>{student.module}</TableCell>
                            <TableCell>{student.status}</TableCell>
                            <TableCell>{student.branch}</TableCell>
                            <TableCell>{student.date_of_join}</TableCell>
                            <TableCell>
                              <div
                                onClick={() => {
                                  handleOpen(student.user_id);
                                }}
                              >
                                <Photo
                                  style={{ marginLeft: 10, color: "rosybrown" }}
                                />
                              </div>
                            </TableCell>
                            <TableCell>
                              {student.payment_method === "full_payment"
                                ? "Full payment"
                                : student.payment_method === "monthly_payment"
                                ? "Monthly payment"
                                : student.payment_method === "quarterly_payment"
                                ? "Quarterly payment"
                                : student.payment_method}
                            </TableCell>

                            <TableCell>
                              <div key={index}>
                                {/* Render toggle button for each student */}
                                <div
                                  className={`toggle-btn ${
                                    toggleStates[student.user_id]?.active
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    toggleButton(
                                      student.user_id,
                                      toggleStates[student.user_id]?.color ||
                                        (student.payment_status
                                          ? "green"
                                          : "red")
                                    )
                                  }
                                  style={{
                                    backgroundColor:
                                      toggleStates[student.user_id]?.color ||
                                      (student.payment_status
                                        ? "green"
                                        : "red"),
                                  }}
                                >
                                  <div className="toggle-handle"></div>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="action-button-student">
                                <div
                                  onClick={() => {
                                    openComponent(student);
                                    setNewData(false);
                                  }}
                                >
                                  <Edit className="action-update" />
                                </div>

                                <div>
                                  <DeleteIcon
                                    className="action-delete"
                                    onClick={() => {
                                      handleDelete(
                                        student.user_id,
                                        `${student.first_name} ${student.last_name}`
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Students;
