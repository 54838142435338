import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import "./style.css";
import { useEffect } from "react";
import store from "../../store";
import { useState } from "react";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TeacherLeaveModal({
  isOpen,
  setIsOpen,

  setRefreshData,
}) {
  const { control, handleSubmit, setValue } = useForm();
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [teacherData, setTeacherData] = useState([]);
  const [leaveType, setLeaveType] = useState("Sick Leave");

  const handleClose = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    const listTeachers = async () => {
      try {
        const response = await fetch(
          "https://api.artvilleacademy.com/admin/list_teachers_api",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setTeacherData(data.teachers);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    listTeachers();
  }, []);
  const onSubmit = async (data) => {
    try {
      const response = await fetch(
        "https://api.artvilleacademy.com/teacher/add_leave",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...data,
            pay_or_no_pay: "",
            status: "Pending",
            teacher_id: parsedData.user_id,
            leave_type: leaveType,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Error submitting data");
      }
      Swal.fire({
        icon: "success",
        text: "Data submitted successfully",
      });
    } catch (error) {
      console.error("Error submitting data:", error.message);
      Swal.fire({
        icon: "error",
        text: "Something went wrong!",
      });
    }
    handleClose();
    setRefreshData((prevState) => !prevState);
  };

  return (
    <>
      <Dialog
        className="style-div"
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Leave Form"}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-text-div">
              <div className="label-text">Leave Type</div>
              <select
                className="form-input"
                required
                value={leaveType}
                onChange={(e) => setLeaveType(e.target.value)}
              >
                <option value="Sick Leave">Sick Leave</option>
                <option value="Casual Leave">Casual Leave</option>
                <option value="Annual Leave">Annual Leave</option>
              </select>
            </div>
            <div className="joint-div">
              <div className="form-text-div">
                <div className="label-text">Start Date</div>
                <Controller
                  name="start_date"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      required
                      className="select-form"
                      {...field}
                      type="date"
                    />
                  )}
                />
              </div>{" "}
              <div className="form-text-div">
                <div className="label-text">End date</div>
                <Controller
                  name="end_date"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      required
                      className="select-form"
                      {...field}
                      type="date"
                    />
                  )}
                />
              </div>
            </div>
            <div className="form-text-div">
              <div className="label-text">Reason</div>
              <Controller
                name="reason"
                control={control}
                render={({ field }) => (
                  <textarea
                    required
                    placeholder=""
                    className="form-input"
                    {...field}
                  />
                )}
              />
            </div>

            <DialogActions>
              <div className="submit-div" type="submit">
                <button className="submit">Submit</button>
              </div>
              <div className="submit-div">
                <Button onClick={handleClose}>Cancel</Button>
              </div>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
