import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import AboutUs from "./components/About";
import Feedback from "./components/Feedback";
import Dashboard from "./views/Dashboard";
import Login from "./views/Login";
import StudentEnrollmentForm from "./views/StudentEnrollementForm/ContactDetails";

function App() {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  // useEffect(() => {
  //   const serializedData = sessionStorage.getItem("userDetails");
  //   if (serializedData) {
  //     const data = JSON.parse(serializedData);
  //     if (data.token) {
  //       setIsLoggedIn(true);
  //     }
  //   }
  // }, [isLoggedIn]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route
            path="/student-enrollment"
            element={<StudentEnrollmentForm />}
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/feedback" element={<Feedback />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
