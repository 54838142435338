import React from "react";
import Logo from "../../assets/images/logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import "./style.css";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isCurrentPage = (path) => location.pathname === path;

  return (
    <div>
      <div className="navbar-div">
        <div className="navbar-div-item">
          <img className="logo" src={Logo} alt="Logo" />
          <div className="navbar-items">
            <div
              className={`navbar-text ${
                isCurrentPage("/feedback") ? "black-text" : ""
              }`}
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </div>
            <div
              className={`navbar-text ${
                isCurrentPage("/feedback") ? "black-text" : ""
              }`}
              onClick={() => {
                navigate("/about-us");
              }}
            >
              About us
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
