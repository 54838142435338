import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import "./style.css";
import Logo from "../../assets/images/logo.png";
import Cards from "../../components/Cards";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import {
  PiCarProfileFill,
  PiPasswordBold,
  PiStudentFill,
} from "react-icons/pi";
import { BsPersonLinesFill, BsPersonVideo } from "react-icons/bs";
import { RiAdminFill, RiCouponLine } from "react-icons/ri";
import { MdEventNote } from "react-icons/md";
import { PiGitBranchDuotone } from "react-icons/pi";
import { GrGallery, GrOverview } from "react-icons/gr";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import Teacher from "../../components/Teachers";
import Students from "../../components/studends";
import store from "../../store";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  AccountBox,
  Approval,
  Logout,
  Payment,
  Person,
  PresentToAll,
  ViewAgenda,
} from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CreateIcon from "@mui/icons-material/Create";
import Courses from "../../components/Courses";
import Attendance from "../../components/Attendance";
import AttendanceCalender from "../../components/Attendance/Calender";
import ViewAttendance from "../../components/Attendance/viewAttendance";
import Branches from "../../components/Branches";
import AdminBranch from "../../components/AdminBranch";
import TeacherOverview from "../../components/Cards/TeacherOverview";
import ApproveStudents from "../../components/studends/ApproveStudents";
import AdminOverview from "../../components/Cards/AdminOverview";
import TeacherLeave from "../../components/LeaveApplication/TeacherLeave";
import AdminLeaveApproval from "../../components/LeaveApplication/AdminLeaveApproval";
import ChangePassword from "./ChangePasword";
import PaymentLinks from "../../components/payementLinks";
import Studentprofile from "../../components/StudentsLogin/Studentprofile";
import ArtGallery from "../../components/StudentsLogin/ArtGallery";
import StudentCalender from "../../components/StudentsLogin/StudentCalender";
const drawerWidth = 240;

const Dashboard = (props) => {
  const navigate = useNavigate();
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  // const parsedData = { access_level: "student" };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openComponent = () => {
    setIsOpen(true);
  };

  const appBarWidth =
    parsedData?.access_level === "branch_admin"
      ? "100%"
      : `calc(100% - ${drawerWidth}px)`;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleItemClick = (index) => {
    setSelectedItem(index);
  };
  const componentsArray = [
    <AdminOverview />,
    <Teacher />,
    <Students />,
    <ApproveStudents />,
    <AdminLeaveApproval />,
    <Courses />,
    <Branches />,
    <AdminBranch />,
    <PaymentLinks />,
  ];
  const icons = [
    <GrOverview />,
    <SupervisorAccountIcon />,
    <PiStudentFill />,
    <Approval />,
    <BsPersonLinesFill />,
    <RiCouponLine />,
    <MdEventNote />,
    <PiGitBranchDuotone />,
    <RiAdminFill />,
    <Payment />,
  ];

  const textArray = [
    "Overview",
    "Teachers",
    "Students",
    "Approve Students",
    "Leave Applications",
    "Courses",
    "Branches",
    "Branch Admins",
    "Add Payment Links",
  ];
  const componentsArrayTeacher = [
    <TeacherOverview />,
    <Students />,
    <ApproveStudents />,
    <AttendanceCalender />,
    <ViewAttendance />,
    <TeacherLeave />,
  ];

  const iconsTeacher = [
    <GrOverview />,
    <SupervisorAccountIcon />,
    <Approval />,
    <CreateIcon />,
    <ViewAgenda />,
    <MdEventNote />,
  ];

  const textArrayTeacher = [
    "Overview",
    "Students",
    "Approve Students",
    "Attendance",
    "View/update Attendance",
    "Leaves",
  ];
  const componentsArrayStudent = [
    <StudentCalender />,
    <Studentprofile />,
    <ArtGallery />,
  ];
  const textArrayStudent = ["Overview", "Profile", "Gallery"];
  const iconsStudent = [<GrOverview />, <AccountBox />, <GrGallery />];

  const componentsArrayBranch = [<Cards />];

  const iconsBranch = [<GrOverview />];

  const textArrayBranch = ["Overview"];

  const renderComponents = () => {
    if (parsedData?.access_level === "admin") {
      return (
        <div className="card-div">
          {selectedItem !== null && componentsArray[selectedItem]}
        </div>
      );
    } else if (parsedData?.access_level === "teacher") {
      return (
        <div className="card-div">
          {selectedItem !== null && componentsArrayTeacher[selectedItem]}
        </div>
      );
    } else if (parsedData?.access_level === "branch_admin") {
      return (
        <div className="card-div">
          {selectedItem !== null && componentsArrayBranch[selectedItem]}
        </div>
      );
    } else if (parsedData?.access_level === "student") {
      return (
        <div className="card-div">
          {selectedItem !== null && componentsArrayStudent[selectedItem]}
        </div>
      );
    }
  };
  const handleLogout = async (e) => {
    try {
      const response = await fetch("https://api.artvilleacademy.com/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: parsedData.token }),
      });

      if (response.ok) {
        sessionStorage.removeItem("userDetails");
        toast.success("Logout successful");
        navigate("/");
      } else {
        toast.error("Logout failed");
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("Error during login");
    }
  };

  let textArrayToMap = [];
  let iconsToUse = [];

  if (parsedData?.access_level === "admin") {
    textArrayToMap = textArray;
    iconsToUse = icons;
  } else if (parsedData?.access_level === "teacher") {
    textArrayToMap = textArrayTeacher;
    iconsToUse = iconsTeacher;
  } else if (parsedData?.access_level === "branch_admin") {
    textArrayToMap = textArrayBranch;
    iconsToUse = iconsBranch;
  } else if (parsedData?.access_level === "student") {
    textArrayToMap = textArrayStudent;
    iconsToUse = iconsStudent;
  }

  const mappedList = textArrayToMap.map((text, index) => (
    <ListItem key={text} disablePadding>
      <ListItemButton onClick={() => handleItemClick(index)}>
        <ListItemIcon className="icon-style">{iconsToUse[index]}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  ));

  const drawer = (
    <div>
      <Toolbar />

      <div>
        {" "}
        <img className="sidebar" src={Logo} alt="Logo" />
      </div>
      {mappedList}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box className="box-div" sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: appBarWidth },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar className="dashboard-nav">
          <MenuIcon
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          ></MenuIcon>
          <div className="nav-button">
            <div>
              <Typography variant="h6" noWrap component="div">
                Dashboard
              </Typography>
            </div>

            <div className="log-out">
              <Button
                className="log-out-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <Person /> Profile
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {parsedData?.access_level == "student" ? (
                  ""
                ) : (
                  <MenuItem
                    onClick={() => {
                      openComponent();
                    }}
                  >
                    Change Password
                  </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>
                  {" "}
                  <Logout className="log-out-icon" />
                  Logout
                </MenuItem>
              </Menu>
              {isOpen && (
                <ChangePassword isOpen={isOpen} setIsOpen={setIsOpen} />
              )}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {parsedData?.access_level === "branch_admin" ? (
        <></>
      ) : (
        <>
          {" "}
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
        </>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {renderComponents()}
      </Box>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Box>
  );
};

export default Dashboard;
