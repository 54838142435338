import React, { useEffect, useState } from "react";
import AttendanceList from "./AttendanceList";
import Swal from "sweetalert2";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DeleteIcon from "@mui/icons-material/Delete";

const ViewAttendance = () => {
  const [studentData, setStudentData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [refreshData, setRefreshData] = useState(false);

  const handleCalendarChange = (newValue) => {
    const formatted = moment(newValue).format("YYYY-MM-DD");
    setSelectedDate(formatted);
  };
  const [open, setOpen] = useState(false);
  const [topics, setTopics] = useState({});
  const [alignment, setAlignment] = useState({});
  const handleTopicChange = (studentId, topicValue) => {
    setTopics({ ...topics, [studentId]: topicValue });
  };
  const handleAlignmentChange = (studentId, alignmentValue) => {
    setAlignment({ ...alignment, [studentId]: alignmentValue });
  };

  const handleAttendanceSubmit = async () => {
    const updates = studentData.map((student, index) => ({
      id: student.id,
      topic: topics[student.user_id] || "", // Get the topic for the specific student
      status: alignment[student.user_id] || "na",
      module: studentData[0].module,
      course: studentData[0].course,
    }));
    if (updates.length) {
      try {
        setOpen(true);
        const response = await fetch(
          "https://api.artvilleacademy.com/teacher/update_student_attendance",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              access_level: parsedData?.access_level,
              teacher_id: parsedData.user_id,
              updates,
            }),
          }
        );

        if (response.ok) {
          Swal.fire({
            icon: "success",
            text: "Attendance marked successfully",
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "Failed to send attendance data",
          });
          console.error("Failed to send attendance data");
        }
      } catch (error) {
        console.error("Error sending attendance data:", error);
      } finally {
        setOpen(false);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const confirmDelete = await Swal.fire({
        title: "Are you sure you want to delete ",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          popup: "my-popup",
        },
        width: 300,
        height: 200,
      });

      if (confirmDelete.isConfirmed) {
        const response = await fetch(
          `https://api.artvilleacademy.com/teacher/delete_student_attendance`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: id,
              access_level: parsedData?.access_level,
            }),
          }
        );

        if (response.ok) {
          Swal.fire({
            icon: "success",
            text: "Delete successfully",
          });
          setRefreshData((prevState) => !prevState);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to delete. Please try again later.",
          });
        }
      }
    } catch (error) {
      console.error("Error deleting teacher:", error);
    }
  };
  useEffect(() => {
    if (selectedDate) {
      const listStudents = async () => {
        try {
          const response = await fetch(
            "https://api.artvilleacademy.com/teacher/view_student_attendance",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                teacher_id: parsedData.user_id,
                access_level: parsedData?.access_level,
                date: selectedDate,
              }),
            }
          );
          if (response.ok) {
            const data = await response.json();
            setStudentData(data);
            const selectedTopics = {};
            data.forEach((student) => {
              selectedTopics[student.user_id] = student.selected_topic || "";
            });
            setTopics(selectedTopics);
            const alignmentData = {};
            data.forEach((student) => {
              alignmentData[student.user_id] = student.status.toLowerCase();
            });
            setAlignment(alignmentData);
          } else {
            // Handle error response
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      listStudents();
    }
  }, [selectedDate, refreshData]);

  return (
    <>
      {selectedDate ? (
        <>
          <div className="add-course-div">
            <button
              className="button-81"
              onClick={() => {
                setSelectedDate(null);
              }}
            >
              <ArrowBackIcon />
            </button>
          </div>
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="names">Sl no</TableCell>

                    <TableCell className="names">Names</TableCell>
                    <TableCell className="names"> Topics</TableCell>
                    <TableCell className="names">Status</TableCell>
                    <TableCell className="names">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentData.map((student, index) => (
                    <TableRow key={student.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{student.name}</TableCell>
                      <TableCell>
                        <select
                          required
                          className="select-attendance"
                          value={topics[student.user_id] || ""}
                          onChange={(e) =>
                            handleTopicChange(student.user_id, e.target.value)
                          }
                        >
                          <option value="">Select Topics</option>
                          {student?.topic?.map((time, index) => (
                            <option key={index} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                      </TableCell>
                      <TableCell>
                        <ToggleButtonGroup
                          value={alignment[student.user_id] || "na"}
                          exclusive
                          onChange={(e, newAlignment) =>
                            handleAlignmentChange(student.user_id, newAlignment)
                          }
                          className="toggle-div"
                        >
                          <ToggleButton
                            className="toggle-btn"
                            value="present"
                            style={{
                              backgroundColor:
                                alignment[student.user_id] === "present"
                                  ? "#43ce18c4"
                                  : "",
                            }}
                          >
                            Present
                          </ToggleButton>
                          <ToggleButton
                            className="toggle-btn"
                            value="na"
                            style={{
                              backgroundColor:
                                alignment[student.user_id] === "na"
                                  ? "#ffb200b3"
                                  : "",
                            }}
                          >
                            Na
                          </ToggleButton>
                          <ToggleButton
                            className="toggle-btn"
                            value="absent"
                            style={{
                              backgroundColor:
                                alignment[student.user_id] === "absent"
                                  ? "#ff2400"
                                  : "",
                            }}
                          >
                            Absent
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <div>
                          <DeleteIcon
                            className="action-delete"
                            onClick={() => handleDelete(student.id)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="add-submit">
              {" "}
              <button className="button-3" onClick={handleAttendanceSubmit}>
                Submit
              </button>
            </div>
          </>
        </>
      ) : (
        <div className="calendar-div">
          <Calendar onChange={handleCalendarChange} value={selectedDate} />
        </div>
      )}
    </>
  );
};

export default ViewAttendance;
