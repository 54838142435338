import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "./style.css";
import { Backdrop, CircularProgress } from "@mui/material";
import BranchAdmin from "./BranchAdmin";

export default function Cards() {
  const storedData = sessionStorage.getItem("userDetails");
  const parsedData = JSON.parse(storedData);
  const [open, setOpen] = useState(false);
  const [adminDetails, setAdminDetails] = useState(null);
  useEffect(() => {
    setOpen(true);
    if (parsedData?.access_level === "branch_admin") {
      const fetchData = async () => {
        try {
          const response = await fetch(
            "https://api.artvilleacademy.com/branch_admin/branch_dashboard",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ user_id: parsedData.user_id }),
            }
          );

          if (response.ok) {
            const data = await response.json();
            setAdminDetails(data.branch_data_list);
            setOpen(false);
          } else {
            throw new Error("Network response was not ok.");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setOpen(false);
        }
      };

      fetchData();
    }
    setOpen(false);
  }, []);

  return (
    <>
      {open ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div>
          {adminDetails &&
            adminDetails?.map((detail, index) => (
              <>
                <div>
                  <h3 className="branch-name">Branch : {detail.branch_name}</h3>
                </div>
                <div className="main-card">
                  <>
                    {/* <Card key={index} className="card-style">
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 20 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Branch Name
                      </Typography>
                      <Typography variant="h5" component="div">
                        {detail.branch_name}
                      </Typography>
                    </CardContent>
                  </Card> */}
                    <Card key={index} className="card-style">
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 20 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Active Students
                        </Typography>
                        <Typography variant="h5" component="div">
                          {detail.active_students_count}
                        </Typography>
                      </CardContent>
                    </Card>
                    <Card className="card-style">
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 20 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Total Expenses
                        </Typography>
                        <Typography variant="h5" component="div">
                          {detail.total_expenses}
                        </Typography>
                      </CardContent>
                    </Card>

                    <Card className="card-style">
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 20 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Total Revenue
                        </Typography>
                        <Typography variant="h5" component="div">
                          {detail.total_revenue}
                        </Typography>
                      </CardContent>
                    </Card>
                    <Card className="card-style">
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 20 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Monthly ROI
                        </Typography>
                        <Typography variant="h5" component="div">
                          {detail.dividend}
                        </Typography>
                      </CardContent>
                    </Card>
                  </>
                </div>
              </>
            ))}
          <div>
            <BranchAdmin />
          </div>
        </div>
      )}
    </>
  );
}
